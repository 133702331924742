.info-list {
    ul {
        li {
            margin-bottom: 7px;
            color: $body-color;
            font-weight: 500;
            padding: 12px 0;

            &:hover,
            &:focus {
                color: $primary;
            }

            i {
                margin-right: 15px;
                color: $primary;
                font-weight: bold;
                font-size: 24px;
            }
        }
    }
}