.header {
    padding      : 10px 0px;
    background   : $dark;
    border-bottom: 0px solid $border-color;
    transition   : all 0.3s ease-in-out;
    z-index      : 999;
    position     : fixed;
    left         : 0;
    right        : 0;
    top          : 0;

    // box-shadow: 0 0.05rem 0.01rem rgba(75,75,90,.075);
    &.fixed {
        padding     : 10px 0px;
        box-shadow  : 0 0.05rem 0.01rem rgba(75, 75, 90, .075);
        border-color: transparent;
    }

    .navigation {

        // text-align: right;
        .navbar {
            background-color: transparent !important;
            padding         : 0px;

            .collapse {
                justify-content: flex-end;
            }

            .navbar-nav {
                align-items: center;

                @include respond("tab-port") {
                    margin-top: 15px;
                }

                &>.nav-item {
                    display       : inline-block;
                    padding       : 7px;
                    // margin-left: 15px;
                    width         : 100%;

                    @include respond("tab-port") {
                        border-bottom: 1px solid $border-color;
                        margin       : 0 15px;
                        padding      : 7px 15px;
                        // background: $primary;
                    }

                    &>a {
                        font-size  : 16px;
                        font-weight: 500;
                        color      : $body-color;

                        & :hover,
                        &:focus,
                        &.active {
                            color: $primary;
                            // @include respond('tab-port') {
                            //     color: $white;
                            // }
                        }

                        @include respond("tab-port") {
                            &::after {
                                position: absolute;
                                right   : 15px;
                                top     : 25px;
                            }
                        }
                    }
                }

                @media only screen and (min-width: 991px) {
                    .dropdown {
                        &>a {
                            position  : relative;
                            transition: all 0.2s ease-in-out;
                            // color  : $gray-700;
                            // &      ::before{
                            //     content            : '';
                            //     display            : block;
                            //     position           : absolute;
                            //     bottom             : -19px;
                            //     left               : 1em;
                            //     height             : 0;
                            //     width              : 0;
                            //     border             : 10px solid transparent;
                            //     border-bottom-color: lighten($primary, 40%);
                            //     z-index            : 999;
                            //     transition         : all .2s ease-in-out;
                            //     opacity            : 0;
                            // }
                        }
                    }

                    .dropdown-menu {
                        background: rgba(0, 0, 0, 0.15);
                        padding   : 15px 0;
                        display   : block;
                        opacity   : 0;
                        visibility: hidden;
                        z-index   : 1030;
                        transition: all 0.2s ease-in-out;
                        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
                        border    : 0px;
                        margin-top: 10px;
                        z-index   : 998;
                        min-width : 10rem;

                        a {
                            display: inline-block;
                            color  : $body-color;

                            &:hover,
                            &:focus,
                            &.active {
                                background: rgba(0, 0, 0, 0.1);
                                color     : $white;
                            }
                        }
                    }

                    .nav-item.dropdown {
                        &:hover {
                            a {
                                &::before {
                                    opacity: 1;
                                    bottom : -9px;
                                }
                            }

                            .dropdown-menu {
                                // display  : block;
                                opacity     : 1;
                                visibility  : visible;
                                // transform: translate3d(-50%,0,0);
                                margin-top  : 0px;
                            }
                        }
                    }
                }

                @include respond("tab-port") {
                    .dropdown-menu {
                        border    : 0px;
                        padding   : 0px;
                        background: rgba(0, 0, 0, 0.15);

                        a {
                            border-bottom: 1px solid $border-color;
                            padding      : 15px;
                            color        : $body-color;

                            // background: #323232;
                            &            :last-child {
                                border: 0px;
                            }

                            i,
                            h6 {
                                color: $primary;
                            }

                            p {
                                color        : $body-color;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.signin-btn {
    .btn {
        padding  : 7px 15px !important;
        // color : $white ;
        min-width: 85px !important;
    }

    @include respond("tab-port") {
        position: absolute;
        right   : 73px;
        top     : 3px;
    }
}