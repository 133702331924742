.header {
    &.dashboard {
        padding         : 5px 10px;
        // border-bottom: 1px solid $border-color;
        box-shadow      : 0 10px 30px 0 rgba(82, 63, 105, .08);

        // margin-left: 75px;
        .navbar {
            padding: 0px;
        }
    }

    .navbar-brand {
        // margin-right: 30px;
        display        : flex;
        align-items    : center;

        img {
            max-width: 115px;
            // filter: brightness(100);
        }

        // @include respond('tab-land') {
        //     margin-right: 30px;
        // }

        span {
            color      : $dark;
            font-weight: 500;
            margin-left: 20px;
            font-size  : 24px;
        }
    }

    // &.fixed {
    //     position  : fixed;
    //     background: $primary;
    //     transition: all 0.3s ease-in;
    // }
}

.header_auth {
    .btn {
        margin-left: 10px;

        @include respond('tab-port') {
            padding  : 7px;
            min-width: 110px;
        }

        @include respond('tab-port') {
            display: none;
        }
    }
}

.navbar-toggler {
    background: rgba(255, 255, 255, 0.2);
    min-width : auto;
}

.navbar-nav {
    @include respond('tab-port') {
        // background: darken($primary, 10%);
        margin-top   : 10px;
        border-radius: 5px;
        // padding   : 10px 0px;
    }

    .nav-item {
        .nav-link {
            // padding : 0 20px;
            color      : $white;
            font-weight: 500;
            opacity    : 0.75;

            // @include respond('tab-port') {
            //     padding: 10px 20px !important;
            // }
            // @include respond('tab-land') {
            //     padding: 10px 15px !important;
            // }
            &:hover,
            &:focus,
            &.active {
                opacity: 1;
                color: $primary;
            }
        }

        // &.active {
        //     .nav-link {
        //         color: $white !important;
        //         @include respond('tab-port') {
        //             color: $white !important;
        //         }
        //     }
        // }
    }
}

// .dashboard_log {
//     @include respond('tab-port') {
//         position: absolute;
//         right   : 0px;
//         top     : 15px;
//         margin  : 0px !important;
//     }
// }

// .dashboard_log {}
// .account_money {
//     margin-right: 20px;
//     @include respond('phone-land') {
//         display: none;
//     }
//     ul {
//         li {
//             display        : inline;
//             justify-content: space-around;
//             &.crypto {
//                 background   : rgba(0,0,0,0.15);
//                 padding      : 8px 15px 8px 22px;
//                 color        : $primary;
//                 border-radius: 30px 0 0px 30px;
//                 border       : 1px solid rgba(0,0,0,0.15);
//                 transition   : all 0.3s ease-in-out;
//                 @include respond('tab-land') {
//                     padding: 8px 10px 8px 20px;
//                 }
//                 @include custommq($min: 1200px, $max: 1350px) {
//                     padding: 8px 10px 8px 20px;
//                 }
//                 span {
//                     margin-right: 10px;
//                     font-weight : 600;
//                     @include respond('tab-land') {
//                         margin-right: 10px;
//                     }
//                     @include custommq($min: 1200px, $max: 1350px) {
//                         margin-right: 10px;
//                     }
//                 }
//                 &:hover,
//                 &:focus,
//                 &.active {
//                     background: $primary;
//                     color     : $white;
//                     border    : 1px solid $primary;
//                 }
//             }
//             &.usd {
//                 border       : 1px solid $border-color;
//                 padding      : 8px 20px 8px 10px;
//                 border-radius: 0px 30px 30px 0px;
//                 @include respond('tab-land') {
//                     padding: 8px 20px 8px 10px;
//                 }
//                 @include custommq($min: 1200px, $max: 1350px) {
//                     padding: 8px 20px 8px 10px;
//                 }
//                 span {
//                     font-weight: 500;
//                     color      : $body-color;
//                 }
//             }
//         }
//     }
// }

.language {
    margin-right: 20px;

    .icon {
        cursor: pointer;

        i {
            margin-right: 7px;
        }
    }

    .dropdown-menu {
        border          : 0px;
        padding         : 5px 0px;
        margin          : 0px;
        top             : 42px;
        min-width       : 100px;
        box-shadow      : 0 10px 20px rgba(92, 119, 252, 0.15);
        border-radius   : 5px;
        background-color: $dark;

        .dropdown-item {
            border-top: 1px solid $border-color;
            color     : $body-color;

            &:first-child {
                border: 0px;
            }

            &:hover,
            &:focus,
            &.active {
                background-color: transparent;
            }
        }



        a {
            padding: 5px 20px;
        }
    }
}

.profile_log {
    cursor: pointer;

    .user {
        display    : flex;
        align-items: center;

        .thumb {
            height       : 32px;
            width        : 32px;
            border-radius: 50px;
            border       : 1px solid $primary;
            display      : inline-block;
            margin-right : 7px;
            background   : $primary;
            color        : $white;
            text-align   : center;
            padding      : 3px 0;

            i {
                font-size  : 18px;
                line-height: 1.3;
            }
        }

        .arrow {

            // color: $white;
            i {
                font-weight: bold;
                font-size  : 14px;
                line-height: 16px;
                margin-top : 6px;
                display    : inline-block;
            }

            @include respond('tab-land') {
                display: none;
            }
        }
    }

    .dropdown-menu {
        border          : 0px;
        padding         : 0px;
        margin          : 0px;
        top             : 47px;
        box-shadow      : 0 10px 20px rgba(92, 119, 252, 0.15);
        border-radius   : 5px;
        background-color: $dark;
        min-width       : 240px;

        .user-email {
            padding: 20px 20px 10px;

            h6 {
                margin-bottom: 0px;
            }
        }

        .user-balance {
            display        : flex;
            justify-content: space-around;
            margin-bottom  : 15px;

            p {
                margin-bottom: 0px;
                font-weight  : 500;
                color        : $headings-color;
            }
        }

        .dropdown-item {
            padding    : 5px 20px;
            border-top : 1px solid $border-color;
            font-size  : 14px;
            color      : $body-color;
            font-weight: 400;
            display    : flex;
            align-items: center;

            &:first-child {
                border: 0px;
            }

            &.logout {
                color: $danger;

                i {
                    color: $danger;
                }
            }

            i {
                margin-right: 10px;
                font-size   : 22px;
                color       : $primary;
                font-weight : bold;
            }

            &:hover,
            &:focus,
            &.active {
                background-color: transparent;
            }
        }
    }
}