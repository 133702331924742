.scroll-wrapper,
.market-limit,
.order-book,
.trade-history,
.depth-chart,
.open-orders .buy-sell-limit {
    position     : relative;
    height       : 400px;
    margin-bottom: 10px;
}

.price-pair,
.balance-widget,
.market-news,
.your-position {
    position     : relative;
    height       : 400px;
    margin-bottom: 10px;
}

.limit-nested {
    position: relative;
    height  : 210px;
}

.market-nested {
    position: relative;
    height  : 270px;
}

.stop-limit-nested {
    position: relative;
    height  : 165px;
}

.open-position-table {
    position     : relative;
    height       : 400px;
    margin-bottom: 10px;
}

.card-header {
        .nav-pills {
            line-height: initial;

            a {
                padding    : 0px 7px;
                color      : $body-color;
                font-weight: 500;

                &.active {
                    background-color: transparent !important;
                    color           : $primary !important;
                    opacity         : 1;
                }
            }
        }
}

.order-book-divider {
    text-align: center;
    padding   : 15px 0px;

    font-family: $barlow;

    h6 {
        margin-bottom: 0px;
    }
}

#chartdiv {
    width : 100%;
    height: 380px;
}






.duration-option {
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);

    a {
        display      : inline-block;
        margin-left  : 10px;
        text-align   : center;
        padding      : 5px 15px;
        border       : 1px solid $border-color;
        // float     : left;
        transition   : all 0.3s ease;
        font-weight  : 500;
        border-radius: 5px;

        // color: $white;
        &       :hover,
        &:focus,
        &.active {
            background  : $primary;
            color       : $white;
            border-right: 1px solid transparent;
        }

        // &:first-child {
        //     border-top-left-radius   : 25px;
        //     border-bottom-left-radius: 25px;
        // }
        // &:last-child {
        //     border: 0px;
        // }
    }
}

.market-news {
    img {
        border-radius: 5px;
    }
}

.list-group-item {
    background-color: $dark;
}


// .chart-stat {
//     background   : rgba(0, 0, 0, 0.15);
//     border-radius: 15px;
//     padding      : 15px 15px;
//     margin-bottom: 15px;
//     @include custommq($min: 1200px, $max: 1350px) {
//         padding: 15px 7px;
//     }
//     h5 {
//         margin-bottom: 5px;
//     }
// }



// .balance-widget {
//     ul {
//         li {
//             padding      : 18px;
//             align-items  : center;
//             border-bottom: 0px solid $border-color;
//             background   : rgba(0, 0, 0, 0.15);
//             border-radius: 15px;
//             margin-bottom: 15px;
//             i {
//                 font-size: 30px;
//             }
//         }
//     }
// }

.total-balance {
    padding   : 10px 0px 20px;
    text-align: center;
}

.open-position-table {
    table {
        tr {

            th,
            td {
                padding: 10px 10px !important;
            }
        }
    }
}

.nav-pills{

}