@import "./intro";
@import "./sponsor";
@import "./promo";
@import "./product";
@import "./brand";
@import "./features";
@import "./special";
@import "./testimonial";
@import "./support";
@import "./what-new";

// Get Start
.getstart {
    // background: #FAFCFE;
}

.getstart-content {
    text-align: center;

    i {
        font-size: 48px;
        color    : $primary;
    }

    h3 {
        margin-top: 15px;
        font-size : 20px;
    }
}

// Promo
.promo {

    // background-color: #fff;
    // border-bottom: 1px solid $border-color;
    .promo-content {
        text-align: center;

        .promo-content-img {
            background     : $primary;
            height         : 150px;
            width          : 150px;
            border-radius  : 15px;
            padding        : 20px;
            display        : flex;
            align-items    : center;
            justify-content: center;
            margin         : 0 auto 30px;

            // box-shadow: rgba(218, 225, 233, 0.557) 0px 8px 16px;
            img {
                max-width: 70px;
            }
        }

        h3 {
            margin-bottom: 15px;
        }

        p {
            line-height: 30px;
        }
    }
}

// portfolio
.portfolio_list {

    // max-width: 500px;
    .d-flex {
        margin-bottom   : 30px;
        // align-items  : center;

        h4 {
            font-size: 22px;
        }

        .port-icon {
            height         : 65px;
            width          : 140px;
            background     : $dark;
            box-shadow     : rgba(0, 0, 0, 0.1) 0px 8px 16px;
            border-radius  : 50%;
            // border      : 1px solid $border-color;
            display        : flex;
            justify-content: center;
            align-items    : center;
            font-size      : 32px;
            margin-right   : 20px;
            color          : $primary
        }

        p {
            margin-bottom: 0px;

            a {
                color: $primary
            }
        }
    }
}

.portfolio_img {
    img {
        border-radius: 5px;
        box-shadow   : 25px 60px 125px -25px rgba(0, 0, 0, .2), 16px 40px 75px -40px rgba(0, 0, 0, .2);
    }
}

// Trade app
.trade-app-content {
    border    : 0px;
    background: $dark;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);

    .card-body {
        padding: 20px 30px;

        span {
            font-size    : 48px;
            margin-bottom: 15px;
            display      : inline-block;
            color        : $primary;
        }

        h4 {
            font-size    : 20px;
            margin-bottom: 15px;
        }

        p {
            min-height: 115px;

            @include respond('tab-land') {
                min-height: 165px;
            }

            @include respond('tab-port') {
                min-height: auto;
            }
        }

        a {
            color      : $primary;
            display    : flex;
            font-weight: 500;
            align-items: center;

            i {
                margin-left: 15px;
                font-size  : 18px;
                font-weight: bold;
            }
        }
    }
}