// .features {
//     background: $white;
// }

// .features-tab {
//     .nav {
//         justify-content: center;
//         margin-bottom: 50px;
//         .nav-item {
//             // margin: 0px 30px;
//             border-bottom: 1px solid #ddd;
//             .nav-link {
//                 color: $headings-color;
//                 font-weight: 500;
//                 padding: 10px 30px;
//                 background: transparent;
//                 border-radius: 0px;
//                 margin-bottom: -1px;
//                 &.active {
//                     background: transparent;
//                     color: $primary;
//                     border-bottom: 1px solid $primary;
//                 }
//             }
//         }
//     }
// }

// .features-content-img {
//     img {
//         max-width: 80%;
//     }
// }

// .features-content-text {
//     h3 {
//         margin-bottom: 20px;
//     }
//     p {
//         font-weight: 300;
//         line-height: 30px
//     }
// }


.features-content {
    text-align: center;

    @include respond('phone-land') {
        margin-bottom: 50px;
    }

    span {
        i {
            color    : $primary;
            font-size: 36px;
        }
    }

    h4 {
        font-size    : 20px;
        margin-top   : 30px;
        margin-bottom: 15px;
        text-overflow: ellipsis;
        overflow     : hidden;
        white-space  : nowrap;
    }

    p {
        line-height: 27px;
        max-width  : 350px;
        margin     : 0 auto 30px;
    }

    a {
        color    : $primary;
        font-size: 14px;
    }
}

