.demo_img {
    text-align   : center;
    margin-bottom: 60px;

    .img-wrap {
        // max-height: 175px;
        overflow     : hidden;
        margin-bottom: 15px;
        box-shadow   : 0px 36px 48px rgba(31, 66, 135, 0.04);
        background   : $dark;
        padding      : 10px;
        border-radius: 5px;
        position: relative;
        &::after{
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: $primary;
            opacity: 0;
        }

        &.light {
            background: $white;
        }

        &:hover,&:focus{
            &::after{
                opacity: 0.5;
            }
        }
    }

    img {
        border-radius: 5px;
    }
}

.intro-demo_img {
    // box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
    box-shadow   : 0px 36px 48px rgba(93, 120, 255, 0.25);
    position     : relative;
    overflow     : hidden;

    // background: $primary;
    // padding   : 10px;
    img {
        border-radius: 5px;
    }

    .dark-img {
        position: absolute;
        // top  : 45px;
        // left : 50px;
        width   : 90%;
        right   : 0px;
        bottom  : 0px;
    }

}



