.mw-150 {
    max-width: 150px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}


.section-padding {
    padding: 100px 0px;

    @include respond("tab-land") {
        padding: 70px 0px;
    }
}

.section-title {
    margin-bottom: 75px;

    h2 {
        text-align : center;
        font-weight: 500;
    }
}

#toast-container {
    top  : 48px;
    right: 15px;

    &>div:hover {
        -moz-box-shadow: 0px;
        box-shadow     : 0px;
        cursor         : pointer;
    }

    .toast {
        min-height     : 120px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        box-shadow     : none;
        border-radius  : 16px;
        width          : 500px;
        padding        : 10px 20px 15px 120px;

        @include respond('phone') {
            width: auto;
        }

        &:hover {
            -moz-box-shadow: 0px;
            box-shadow     : 0px;
        }
    }

    canvas {
        position : absolute;
        z-index  : 999;
        top      : 50%;
        transform: translateY(-50%);
        left     : 20px;
    }

    .toast-message {
        max-width: 300px;
    }

    .progress_count {
        position  : absolute;
        color     : $dark;
        font-size : 18px;
        left      : 36px;
        text-align: center;
        right     : 0;
        width     : 50px;
        height    : 100%;
        top       : 50px;
    }

    .la-close {
        position : absolute;
        right    : 20px;
        top      : 15px;
        width    : 24px;
        font-size: 24px;
    }

    a {
        display      : inline-block;
        width        : 80px;
        padding      : 4px;
        text-align   : center;
        position     : absolute;
        bottom       : 15px;
        right        : 20px;
        background   : #ffdeb0;
        border-radius: 30px;
        color        : $warning;
    }
}

.toast-progress {
    background: $warning;
    opacity   : 6;
}

.p2col {
    column-count: 2;
    column-gap  : 50px;

    @include respond("phone-land") {
        column-count: 1;
    }
}

// Scrollbar
::-webkit-scrollbar {
    width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background   : #999;
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #777;
}



//@extend





// .range-style {
//   position          : relative;
//   z-index           : 1000;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : transparent;
//   height            : 6px;
//   border-radius     : 3px;
//   outline           : none;
//   margin            : 0;
//   padding           : 0;
//   width             : 100%;
//   box-shadow        : 0 0 1px rgba(0, 0, 0, 0.9);
// }

.thumb-style {
    position          : relative;
    -webkit-appearance: none;
    -moz-appearance   : none;
    appearance        : none;
    width             : 15px;
    height            : 15px;
    background        : $primary;
    border            : 1px solid darken($primary, 10%);
    border-radius     : 100%;
    cursor            : pointer;
    z-index           : 5000;
    -webkit-transition: background 0.3s ease-in-out;
    transition        : background 0.3s ease-in-out;
}

.thumb-style_hover {
    background        : darken($primary, 10%);
    -webkit-transition: background 0.3s ease-in-out;
    transition        : background 0.3s ease-in-out;
}

.slider-container {
    display : inline-block;
    position: relative;
    width   : 100%;
    margin  : 15px 0 20px;
}

input[type='range'] {
    position          : relative;
    z-index           : 1000;
    -webkit-appearance: none;
    -moz-appearance   : none;
    appearance        : none;
    background        : transparent;
    height            : 15px;
    border-radius     : 3px;
    outline           : none;
    margin            : 0;
    padding           : 0;
    width             : 100%;
    box-shadow        : 0 0 1px rgba(0, 0, 0, 0.9);

    &::-webkit-slider-thumb {
        position          : relative;
        -webkit-appearance: none;
        appearance        : none;
        width             : 20px;
        height            : 20px;
        background        : $primary;
        border            : 1px solid darken($primary, 10%);
        border-radius     : 100%;
        cursor            : pointer;
        z-index           : 5000;
        -webkit-transition: background 0.3s ease-in-out;
        transition        : background 0.3s ease-in-out;

        &:hover,
        &:active {
            background        : darken($primary, 10%);
            -webkit-transition: background 0.3s ease-in-out;
            transition        : background 0.3s ease-in-out;
        }
    }

    &::-moz-range-track {
        background: transparent;
    }

    &::-moz-range-thumb {
        position       : relative;
        -moz-appearance: none;
        appearance     : none;
        width          : 20px;
        height         : 20px;
        background     : $primary;
        border         : 1px solid darken($primary, 10%);
        border-radius  : 100%;
        cursor         : pointer;
        z-index        : 5000;
        -moz-transition: background 0.3s ease-in-out;
        transition     : background 0.3s ease-in-out;

        &:hover,
        &:active {
            background     : darken($primary, 10%);
            -moz-transition: background 0.3s ease-in-out;
            transition     : background 0.3s ease-in-out;
        }
    }
}

.fill {
    position     : absolute;
    z-index      : 50;
    top          : 2px;
    height       : 15px;
    width        : 0%;
    border-radius: 3px;
    background   : -webkit-gradient(linear, left top, right top, from(#d6e6fa), to(darken($primary, 10%)));
    background   : linear-gradient(to right, #d6e6fa 0%, darken($primary, 10%) 100%);
}

.count {
    position     : absolute;
    top          : -25px;
    left         : 0;
    border       : 1px solid darken($primary, 10%);
    background   : $primary;
    color        : #fff;
    padding      : 2px 5px;
    border-radius: 20px;
    text-align   : center;
    // width     : 18px;
    margin-left  : 0px;
    font-size    : 11px;
}


.dropdown-menu {
    background-color: $dark;
    box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);

    .dropdown-item {
        border-top: 1px solid $border-color;
        color     : $body-color;


        &:first-child {
            border: 0px;
        }

        &:hover,
        &:focus,
        &.active {
            background-color: transparent;
        }
    }
}

