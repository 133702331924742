/*
    Name: Reoncapital
    Author: Reoncapital
    Portfolio : https://themeforest.net/user/Reoncapital/portfolio
    Email: Reoncapital.com@gmail.com


    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    6. Dashboard
    7. Pages
    
    
*/
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/font-awesome.min.css");
@import url("../icons/cryptocoins/css/cryptocoins.css");
@import url("../icons/cryptocoins/css/cryptocoins-colors.css");
@import url("../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("../icons/flag-icon-css/flag-icon.min.css");
:root {
  --bs-primary: #5d78ff;
  --bs-secondary: #673BB7;
  --bs-success: #10d876;
  --bs-info: #2754e6;
  --bs-warning: #FE9431;
  --bs-danger: #E50202;
  --bs-light: #f8f9fa;
  --bs-dark: #131722;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: #000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5d78ff;
  text-decoration: underline; }
  a:hover {
    color: #4a60cc; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #AEAED5;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #000;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 10px);
  padding-left: var(--bs-gutter-x, 10px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1199px; } }

.row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1440) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #a1a0a7;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #a1a0a7;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #a1a0a7;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #a1a0a7;
  vertical-align: top;
  border-color: #363C4E; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #dfe4ff;
  --bs-table-striped-bg: #d4d9f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9cde6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ced3ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c9cde6; }

.table-secondary {
  --bs-table-bg: #e1d8f1;
  --bs-table-striped-bg: #d6cde5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbc2d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0c8df;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbc2d9; }

.table-success {
  --bs-table-bg: #cff7e4;
  --bs-table-striped-bg: #c5ebd9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badecd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe4d3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badecd; }

.table-info {
  --bs-table-bg: #d4ddfa;
  --bs-table-striped-bg: #c9d2ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfc7e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4cce7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bfc7e1; }

.table-warning {
  --bs-table-bg: #ffead6;
  --bs-table-striped-bg: #f2decb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d3c1; }

.table-danger {
  --bs-table-bg: #facccc;
  --bs-table-striped-bg: #eec2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7bdbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1b8b8; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #131722;
  --bs-table-striped-bg: #1f232d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2b2e38;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #252833;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #2b2e38; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #AEAED5; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #a1a0a7;
    background-color: #fff;
    border-color: #aebcff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #a1a0a7;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #a1a0a7;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px)); }

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px)); }

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px)); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #aebcff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #a1a0a7; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #aebcff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }
  .form-check-input:checked {
    background-color: #5d78ff;
    border-color: #5d78ff; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #5d78ff;
    border-color: #5d78ff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23aebcff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #000, 0 0 0 0rem rgba(93, 120, 255, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #000, 0 0 0 0rem rgba(93, 120, 255, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5d78ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ced7ff; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5d78ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #ced7ff; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a1a0a7;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #10d876; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(16, 216, 118, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #10d876;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #10d876;
    box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #10d876; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #10d876;
    box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #10d876; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #10d876; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #10d876; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #E50202; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: rgba(229, 2, 2, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E50202;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E50202;
    box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #E50202; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #E50202;
    box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #E50202; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #E50202; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #E50202; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #a1a0a7;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #a1a0a7; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #000;
  background-color: #5d78ff;
  border-color: #5d78ff; }
  .btn-primary:hover {
    color: #000;
    background-color: #758cff;
    border-color: #6d86ff; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #000;
    background-color: #758cff;
    border-color: #6d86ff;
    box-shadow: 0 0 0 0rem rgba(79, 102, 217, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #7d93ff;
    border-color: #6d86ff; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(79, 102, 217, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #000;
    background-color: #5d78ff;
    border-color: #5d78ff; }

.btn-secondary {
  color: #fff;
  background-color: #673BB7;
  border-color: #673BB7; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #58329c;
    border-color: #522f92; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #58329c;
    border-color: #522f92;
    box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #522f92;
    border-color: #4d2c89; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7; }

.btn-success {
  color: #000;
  background-color: #10d876;
  border-color: #10d876; }
  .btn-success:hover {
    color: #000;
    background-color: #34de8b;
    border-color: #28dc84; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #34de8b;
    border-color: #28dc84;
    box-shadow: 0 0 0 0rem rgba(14, 184, 100, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #40e091;
    border-color: #28dc84; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(14, 184, 100, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #10d876;
    border-color: #10d876; }

.btn-info {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6; }
  .btn-info:hover {
    color: #fff;
    background-color: #2147c4;
    border-color: #1f43b8; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fff;
    background-color: #2147c4;
    border-color: #1f43b8;
    box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1f43b8;
    border-color: #1d3fad; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #fff;
    background-color: #2754e6;
    border-color: #2754e6; }

.btn-warning {
  color: #000;
  background-color: #FE9431;
  border-color: #FE9431; }
  .btn-warning:hover {
    color: #000;
    background-color: #fea450;
    border-color: #fe9f46; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #fea450;
    border-color: #fe9f46;
    box-shadow: 0 0 0 0rem rgba(216, 126, 42, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #fea95a;
    border-color: #fe9f46; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(216, 126, 42, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #FE9431;
    border-color: #FE9431; }

.btn-danger {
  color: #fff;
  background-color: #E50202;
  border-color: #E50202; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c30202;
    border-color: #b70202; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #c30202;
    border-color: #b70202;
    box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b70202;
    border-color: #ac0202; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #E50202;
    border-color: #E50202; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #131722;
  border-color: #131722; }
  .btn-dark:hover {
    color: #fff;
    background-color: #10141d;
    border-color: #0f121b; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #10141d;
    border-color: #0f121b;
    box-shadow: 0 0 0 0rem rgba(54, 58, 67, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0f121b;
    border-color: #0e111a; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(54, 58, 67, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #131722;
    border-color: #131722; }

.btn-outline-primary {
  color: #5d78ff;
  border-color: #5d78ff; }
  .btn-outline-primary:hover {
    color: #000;
    background-color: #5d78ff;
    border-color: #5d78ff; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #5d78ff;
    border-color: #5d78ff; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #5d78ff;
    background-color: transparent; }

.btn-outline-secondary {
  color: #673BB7;
  border-color: #673BB7; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #673BB7;
    border-color: #673BB7; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #673BB7;
    background-color: transparent; }

.btn-outline-success {
  color: #10d876;
  border-color: #10d876; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #10d876;
    border-color: #10d876; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #10d876;
    border-color: #10d876; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #10d876;
    background-color: transparent; }

.btn-outline-info {
  color: #2754e6;
  border-color: #2754e6; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2754e6;
    border-color: #2754e6; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #2754e6;
    border-color: #2754e6; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #2754e6;
    background-color: transparent; }

.btn-outline-warning {
  color: #FE9431;
  border-color: #FE9431; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #FE9431;
    border-color: #FE9431; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #FE9431;
    border-color: #FE9431; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #FE9431;
    background-color: transparent; }

.btn-outline-danger {
  color: #E50202;
  border-color: #E50202; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #E50202;
    border-color: #E50202; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #E50202;
    border-color: #E50202; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #E50202;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #131722;
  border-color: #131722; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #131722;
    border-color: #131722; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0rem rgba(19, 23, 34, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #131722;
    border-color: #131722; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(19, 23, 34, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #131722;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #5d78ff;
  text-decoration: underline; }
  .btn-link:hover {
    color: #4a60cc; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #a1a0a7;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #5d78ff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #5d78ff; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #5d78ff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #4a60cc; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #000;
    border-color: #dee2e6 #dee2e6 #000; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5d78ff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 10px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #a1a0a7;
  text-align: left;
  background-color: #000;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #546ce6;
    background-color: #eff2ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23546ce6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a1a0a7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #aebcff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #5d78ff;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #4a60cc;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #4a60cc;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5d78ff;
  border-color: #5d78ff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #384899;
  background-color: #dfe4ff;
  border-color: #ced7ff; }
  .alert-primary .alert-link {
    color: #2d3a7a; }

.alert-secondary {
  color: #3e236e;
  background-color: #e1d8f1;
  border-color: #d1c4e9; }
  .alert-secondary .alert-link {
    color: #321c58; }

.alert-success {
  color: #06562f;
  background-color: #cff7e4;
  border-color: #b7f3d6; }
  .alert-success .alert-link {
    color: #054526; }

.alert-info {
  color: #17328a;
  background-color: #d4ddfa;
  border-color: #beccf8; }
  .alert-info .alert-link {
    color: #12286e; }

.alert-warning {
  color: #98591d;
  background-color: #ffead6;
  border-color: #ffdfc1; }
  .alert-warning .alert-link {
    color: #7a4717; }

.alert-danger {
  color: #890101;
  background-color: #facccc;
  border-color: #f7b3b3; }
  .alert-danger .alert-link {
    color: #6e0101; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  color: #0b0e14;
  background-color: #d0d1d3;
  border-color: #b8b9bd; }
  .alert-dark .alert-link {
    color: #090b10; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5d78ff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #a1a0a7;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5d78ff;
    border-color: #5d78ff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #384899;
  background-color: #dfe4ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #384899;
    background-color: #c9cde6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #384899;
    border-color: #384899; }

.list-group-item-secondary {
  color: #3e236e;
  background-color: #e1d8f1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3e236e;
    background-color: #cbc2d9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e236e;
    border-color: #3e236e; }

.list-group-item-success {
  color: #06562f;
  background-color: #cff7e4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #06562f;
    background-color: #badecd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #06562f;
    border-color: #06562f; }

.list-group-item-info {
  color: #17328a;
  background-color: #d4ddfa; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #17328a;
    background-color: #bfc7e1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #17328a;
    border-color: #17328a; }

.list-group-item-warning {
  color: #98591d;
  background-color: #ffead6; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #98591d;
    background-color: #e6d3c1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #98591d;
    border-color: #98591d; }

.list-group-item-danger {
  color: #890101;
  background-color: #facccc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #890101;
    background-color: #e1b8b8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #890101;
    border-color: #890101; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #0b0e14;
  background-color: #d0d1d3; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0b0e14;
    background-color: #bbbcbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0b0e14;
    border-color: #0b0e14; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(93, 120, 255, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast:not(.showing):not(.show) {
    opacity: 0; }
  .toast.hide {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 10px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #363C4E;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #363C4E;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #a1a0a7; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #5d78ff; }
  .link-primary:hover, .link-primary:focus {
    color: #7d93ff; }

.link-secondary {
  color: #673BB7; }
  .link-secondary:hover, .link-secondary:focus {
    color: #522f92; }

.link-success {
  color: #10d876; }
  .link-success:hover, .link-success:focus {
    color: #40e091; }

.link-info {
  color: #2754e6; }
  .link-info:hover, .link-info:focus {
    color: #1f43b8; }

.link-warning {
  color: #FE9431; }
  .link-warning:hover, .link-warning:focus {
    color: #fea95a; }

.link-danger {
  color: #E50202; }
  .link-danger:hover, .link-danger:focus {
    color: #b70202; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb; }

.link-dark {
  color: #131722; }
  .link-dark:hover, .link-dark:focus {
    color: #0f121b; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #363C4E !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #363C4E !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #363C4E !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #363C4E !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #363C4E !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5d78ff !important; }

.border-secondary {
  border-color: #673BB7 !important; }

.border-success {
  border-color: #10d876 !important; }

.border-info {
  border-color: #2754e6 !important; }

.border-warning {
  border-color: #FE9431 !important; }

.border-danger {
  border-color: #E50202 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #131722 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: 50px !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 1rem !important; }

.fs-6 {
  font-size: 0.875rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #5d78ff !important; }

.text-secondary {
  color: #673BB7 !important; }

.text-success {
  color: #10d876 !important; }

.text-info {
  color: #2754e6 !important; }

.text-warning {
  color: #FE9431 !important; }

.text-danger {
  color: #E50202 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #131722 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #a1a0a7 !important; }

.text-muted {
  color: #AEAED5 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #5d78ff !important; }

.bg-secondary {
  background-color: #673BB7 !important; }

.bg-success {
  background-color: #10d876 !important; }

.bg-info {
  background-color: #2754e6 !important; }

.bg-warning {
  background-color: #FE9431 !important; }

.bg-danger {
  background-color: #E50202 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #131722 !important; }

.bg-body {
  background-color: #000 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.875rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body {
  line-height: 1.75; }
  body.fixed {
    overflow: hidden;
    position: fixed; }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

a {
  text-decoration: none;
  outline: none; }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none;
    color: #5d78ff; }

b,
strong,
.strong {
  font-weight: 500;
  color: #fff; }

.h-0 {
  height: 0; }

#main-wrapper {
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px; }
  #main-wrapper.show {
    opacity: 1; }

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px; }

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 999999999; }

.sk-three-bounce {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #000; }

.sk-three-bounce .sk-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #5d78ff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.mw-150 {
  max-width: 150px; }

.fs-18 {
  font-size: 18px; }

.fs-19 {
  font-size: 19px; }

.fs-20 {
  font-size: 20px; }

.section-padding {
  padding: 100px 0px; }
  @media only screen and (max-width: 1199px) {
    .section-padding {
      padding: 70px 0px; } }

.section-title {
  margin-bottom: 75px; }
  .section-title h2, .section-title .h2 {
    text-align: center;
    font-weight: 500; }

#toast-container {
  top: 48px;
  right: 15px; }
  #toast-container > div:hover {
    -moz-box-shadow: 0px;
    box-shadow: 0px;
    cursor: pointer; }
  #toast-container .toast {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: none;
    border-radius: 16px;
    width: 500px;
    padding: 10px 20px 15px 120px; }
    @media only screen and (max-width: 575px) {
      #toast-container .toast {
        width: auto; } }
    #toast-container .toast:hover {
      -moz-box-shadow: 0px;
      box-shadow: 0px; }
  #toast-container canvas {
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    left: 20px; }
  #toast-container .toast-message {
    max-width: 300px; }
  #toast-container .progress_count {
    position: absolute;
    color: #131722;
    font-size: 18px;
    left: 36px;
    text-align: center;
    right: 0;
    width: 50px;
    height: 100%;
    top: 50px; }
  #toast-container .la-close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 24px;
    font-size: 24px; }
  #toast-container a {
    display: inline-block;
    width: 80px;
    padding: 4px;
    text-align: center;
    position: absolute;
    bottom: 15px;
    right: 20px;
    background: #ffdeb0;
    border-radius: 30px;
    color: #FE9431; }

.toast-progress {
  background: #FE9431;
  opacity: 6; }

.p2col {
  column-count: 2;
  column-gap: 50px; }
  @media only screen and (max-width: 767px) {
    .p2col {
      column-count: 1; } }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; }

.thumb-style {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #5d78ff;
  border: 1px solid #2a4eff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 5000;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out; }

.thumb-style_hover {
  background: #2a4eff;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out; }

.slider-container {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 15px 0 20px; }

input[type='range'] {
  position: relative;
  z-index: 1000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  height: 15px;
  border-radius: 3px;
  outline: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.9); }
  input[type='range']::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #5d78ff;
    border: 1px solid #2a4eff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 5000;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out; }
    input[type='range']::-webkit-slider-thumb:hover, input[type='range']::-webkit-slider-thumb:active {
      background: #2a4eff;
      -webkit-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out; }
  input[type='range']::-moz-range-track {
    background: transparent; }
  input[type='range']::-moz-range-thumb {
    position: relative;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #5d78ff;
    border: 1px solid #2a4eff;
    border-radius: 100%;
    cursor: pointer;
    z-index: 5000;
    -moz-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out; }
    input[type='range']::-moz-range-thumb:hover, input[type='range']::-moz-range-thumb:active {
      background: #2a4eff;
      -moz-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out; }

.fill {
  position: absolute;
  z-index: 50;
  top: 2px;
  height: 15px;
  width: 0%;
  border-radius: 3px;
  background: -webkit-gradient(linear, left top, right top, from(#d6e6fa), to(#2a4eff));
  background: linear-gradient(to right, #d6e6fa 0%, #2a4eff 100%); }

.count {
  position: absolute;
  top: -25px;
  left: 0;
  border: 1px solid #2a4eff;
  background: #5d78ff;
  color: #fff;
  padding: 2px 5px;
  border-radius: 20px;
  text-align: center;
  margin-left: 0px;
  font-size: 11px; }

.dropdown-menu {
  background-color: #131722;
  box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15); }
  .dropdown-menu .dropdown-item {
    border-top: 1px solid #363C4E;
    color: #a1a0a7; }
    .dropdown-menu .dropdown-item:first-child {
      border: 0px; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item.active {
      background-color: transparent; }

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

.card {
  border: 0px solid #363C4E;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background: #131722; }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #363C4E;
    background: #131722;
    padding: 15px 20px; }
  .card-title {
    font-size: 16px;
    margin-bottom: 0px; }
  .card .card-body {
    padding: 15px 20px;
    background: #131722;
    border-radius: 15px; }

.mb-3 {
  position: relative; }
  .mb-3 label {
    font-size: 14px;
    font-weight: 500;
    color: #a1a0a7;
    margin-bottom: 10px;
    background: transparent;
    border-color: #363C4E; }

.form-control {
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 400;
  color: #a1a0a7;
  transition: all 0.3s ease-in-out;
  background: #000; }
  .form-control .select {
    padding: 12px 0; }
  .form-control span {
    margin-top: 0; }
  .form-control::-webkit-input-placeholder {
    color: #a1a0a7; }
  .form-control:-ms-input-placeholder {
    color: #a1a0a7; }
  .form-control::placeholder {
    color: #a1a0a7; }
  .form-control:hover {
    box-shadow: none !important;
    outline: none;
    border-color: #363C4E;
    color: #a1a0a7;
    background: #000; }
  .form-control:focus, .form-control:active, .form-control.active {
    box-shadow: none !important;
    outline: none;
    border-color: #5d78ff;
    color: #a1a0a7;
    background: #000; }

input:-internal-autofill-selected {
  background: #050505 !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important; }

.input-group-text {
  padding: 5px 15px;
  background: #000;
  margin-bottom: 0px !important;
  color: #a1a0a7;
  border-color: transparent; }

.input-group-append .input-group-text {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.input-group-prepend .input-group-text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

/*Styling Selectbox*/
.toggle {
  cursor: pointer;
  display: block; }

.toggle-switch {
  display: inline-block;
  background: black;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s; }
  .toggle-switch:before, .toggle-switch:after {
    content: ""; }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s; }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%); }
  .toggle-checkbox:checked + .toggle-switch {
    background: #5d78ff; }
    .toggle-checkbox:checked + .toggle-switch:before {
      left: 26px; }

.toggle-checkbox {
  position: absolute;
  visibility: hidden; }

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400; }

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #363C4E;
  border-radius: 5px;
  color: #AEAED5; }
  .file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden; }
  .file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: #5d78ff;
    color: #fff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0; }
  .file-upload-wrapper:hover:before {
    background: #2a4eff; }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

#ui-datepicker-div {
  display: none;
  background: black;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #a1a0a7; }

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0; }
  .ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #a1a0a7;
    font-size: 0.875rem;
    text-decoration: none; }
    .ui-datepicker-calendar tbody td a:hover {
      background-color: #5d78ff;
      color: white; }
    .ui-datepicker-calendar tbody td a.ui-state-active {
      background-color: #5d78ff;
      color: white; }

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all; }
  .ui-datepicker-header a.ui-corner-all:hover {
    background-color: #f6f8ff;
    color: #fff; }

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg); }

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%; }

.ui-datepicker-header a > span {
  display: none; }

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem; }

.ui-datepicker-week-col {
  color: #a1a0a7;
  font-weight: 400;
  font-size: 0.75rem; }

label.error {
  color: #E50202;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400; }

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }
  .nice-select ul {
    width: 100% !important; }

.nice-select .list {
  background: #131722; }

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #131722;
  color: #5d78ff; }

.table-responsive-sm {
  min-width: 48rem; }

.table {
  margin-bottom: 0px;
  font-weight: 400; }
  .table tr td,
  .table tr th {
    border: 0px;
    vertical-align: middle;
    padding: 15px; }
    .table tr td:last-child,
    .table tr th:last-child {
      text-align: right; }

#dashboard .table tr td,
#dashboard .table tr th {
  padding: 5px 7px; }

.table tr th {
  padding: 0px 5px;
  color: #fff;
  font-weight: 500; }

.table-striped thead tr th {
  padding-bottom: 15px !important; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #000; }

.table-striped tfoot {
  border-top: 2px solid #363C4E; }
  .table-striped tfoot tr th {
    padding-top: 10px; }

.scroll-wrapper,
.market-limit,
.order-book,
.trade-history,
.depth-chart,
.open-orders .buy-sell-limit {
  position: relative;
  height: 400px;
  margin-bottom: 10px; }

.price-pair,
.balance-widget,
.market-news,
.your-position {
  position: relative;
  height: 400px;
  margin-bottom: 10px; }

.limit-nested {
  position: relative;
  height: 210px; }

.market-nested {
  position: relative;
  height: 270px; }

.stop-limit-nested {
  position: relative;
  height: 165px; }

.open-position-table {
  position: relative;
  height: 400px;
  margin-bottom: 10px; }

.card-header .nav-pills {
  line-height: initial; }
  .card-header .nav-pills a {
    padding: 0px 7px;
    color: #a1a0a7;
    font-weight: 500; }
    .card-header .nav-pills a.active {
      background-color: transparent !important;
      color: #5d78ff !important;
      opacity: 1; }

.order-book-divider {
  text-align: center;
  padding: 15px 0px;
  font-family: "Barlow", sans-serif; }
  .order-book-divider h6, .order-book-divider .h6 {
    margin-bottom: 0px; }

#chartdiv {
  width: 100%;
  height: 380px; }

.duration-option {
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04); }
  .duration-option a {
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    padding: 5px 15px;
    border: 1px solid #363C4E;
    transition: all 0.3s ease;
    font-weight: 500;
    border-radius: 5px; }
    .duration-option a :hover, .duration-option a:focus, .duration-option a.active {
      background: #5d78ff;
      color: #fff;
      border-right: 1px solid transparent; }

.market-news img {
  border-radius: 5px; }

.list-group-item {
  background-color: #131722; }

.total-balance {
  padding: 10px 0px 20px;
  text-align: center; }

.open-position-table table tr th,
.open-position-table table tr td {
  padding: 10px 10px !important; }

.intro {
  padding: 100px 0px; }
  .intro .intro-content h1, .intro .intro-content .h1 {
    line-height: 55px;
    margin-bottom: 30px; }
  .intro .intro-content p {
    font-size: 20px;
    margin-bottom: 50px; }

.intro-form {
  position: relative;
  max-width: 450px;
  margin: 0 auto 50px; }
  .intro-form form {
    display: flex; }
  .intro-form button {
    position: relative;
    background: #5d78ff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 7px 20px;
    color: #fff;
    font-size: 24px;
    overflow: hidden;
    width: 60px;
    min-width: 60px;
    border: 0px;
    margin-left: 15px; }
    .intro-form button .first {
      position: absolute;
      right: 17px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out; }
    .intro-form button .second {
      position: absolute;
      left: -22px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out; }
    .intro-form button:hover, .intro-form button:focus {
      outline: none; }
      .intro-form button:hover .first, .intro-form button:focus .first {
        right: -22px; }
      .intro-form button:hover .second, .intro-form button:focus .second {
        left: 20px; }

@media only screen and (max-width: 991px) {
  .intro-btn {
    margin-bottom: 50px; } }

.intro-btn .btn-primary {
  padding: 10px 30px;
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px; }

.intro-btn .btn-outline-primary {
  padding: 10px 30px;
  font-weight: 500;
  font-size: 16px; }

.intro-form-exchange {
  padding: 30px;
  box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  border-radius: 5px;
  background: #131722; }
  .intro-form-exchange .nice-select {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }
  .intro-form-exchange .btn {
    padding: 10px 15px;
    font-weight: 500;
    position: relative;
    font-size: 16px; }
    .intro-form-exchange .btn i {
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      right: 15px; }

.sponsor {
  border-top: 1px solid #363C4E;
  border-bottom: 1px solid #363C4E; }

.sponsor-logo {
  display: flex !important;
  justify-content: space-between; }
  .sponsor-logo a {
    display: block;
    margin-bottom: 50px; }

.trusted-logo {
  text-align: center;
  margin-bottom: 30px; }
  .trusted-logo a {
    display: inline-block; }
    .trusted-logo a img {
      filter: brightness(100); }

.features-content {
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .features-content {
      margin-bottom: 50px; } }
  .features-content span i {
    color: #5d78ff;
    font-size: 36px; }
  .features-content h4, .features-content .h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .features-content p {
    line-height: 27px;
    max-width: 350px;
    margin: 0 auto 30px; }
  .features-content a {
    color: #5d78ff;
    font-size: 14px; }

.get-touch-content {
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  background: #131722;
  border-radius: 5px; }
  .get-touch-content span {
    margin-right: 15px; }
    .get-touch-content span i {
      color: #5d78ff;
      font-size: 42px; }
  .get-touch-content h4, .get-touch-content .h4 {
    font-size: 20px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .get-touch-content p {
    line-height: 27px; }

.testimonial-content {
  background: #131722;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
  border-radius: 5px; }
  .testimonial-content .owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: -29px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    min-width: auto;
    border-radius: 50px;
    background: #5d78ff;
    color: #fff;
    font-size: 18px; }
    .testimonial-content .owl-carousel .owl-nav .owl-prev:hover, .testimonial-content .owl-carousel .owl-nav .owl-prev:focus {
      outline: none; }
  .testimonial-content .owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: -29px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    min-width: auto;
    border-radius: 50px;
    background: #5d78ff;
    color: #fff;
    font-size: 18px; }
    .testimonial-content .owl-carousel .owl-nav .owl-next:hover, .testimonial-content .owl-carousel .owl-nav .owl-next:focus {
      outline: none; }

.customer-img img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative; }
  .customer-img img::before {
    position: absolute;
    content: ''; }

.customer-review {
  padding: 30px; }
  .customer-review img {
    width: auto !important;
    margin-bottom: 30px;
    filter: brightness(100); }
  .customer-review p {
    margin-bottom: 0px; }

.customer-info {
  margin-top: 20px; }
  .customer-info h6, .customer-info .h6 {
    font-size: 18px; }

@media only screen and (max-width: 991px) {
  .appss-content {
    margin-top: 50px;
    margin-bottom: 50px; } }

.appss-content h2, .appss-content .h2 {
  margin-bottom: 30px;
  font-weight: 500; }

.appss-content ul li {
  padding: 10px 0;
  font-size: 17px; }
  .appss-content ul li i {
    font-weight: bold;
    font-size: 18px;
    color: #5d78ff;
    margin-right: 15px; }

.appss-content .btn {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  margin-right: 15px; }

.whatsNew-tab .nav {
  flex-direction: column; }
  .whatsNew-tab .nav .nav-item {
    margin-bottom: 30px; }
    .whatsNew-tab .nav .nav-item .nav-link {
      background: transparent;
      color: #fff;
      font-size: 20px;
      padding: 15px 15px;
      border: 1px solid #5d78ff;
      transition: all 0.5s ease-in-out; }
      .whatsNew-tab .nav .nav-item .nav-link:hover, .whatsNew-tab .nav .nav-item .nav-link:focus, .whatsNew-tab .nav .nav-item .nav-link.active {
        background: white;
        color: #5d78ff;
        border: 1px solid #5d78ff; }

.accordion {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1); }
  .accordion__item {
    margin-bottom: -1px; }
  .accordion__header {
    padding: 0.9375rem;
    border: 1px solid #363C4E;
    cursor: pointer;
    position: relative;
    font-weight: 500; }
    .accordion__header--indicator {
      font-family: 'LineAwesome';
      position: absolute;
      right: 1.5625rem;
      top: 50%;
      transform: translateY(-50%); }
      .accordion__header--indicator.indicator_bordered {
        display: inline-block;
        width: 25px;
        text-align: center;
        height: 25px;
        border: 1px solid #ddd;
        border-radius: 50%;
        line-height: 25px; }
    .accordion__header:not(.collapsed) .accordion__header--indicator::before {
      content: "\f110"; }
    .accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
      content: "\f113"; }
    .accordion__header.collapsed .accordion__header--indicator::before {
      content: "\f113"; }
    .accordion__header.collapsed .accordion__header--indicator.style_two::before {
      content: "\f110"; }
  .accordion__body--text {
    padding: 0.9375rem; }
  .accordion-active-header .accordion__header:not(.collapsed) {
    background-color: #5d78ff;
    color: #fff; }

.getstart-content {
  text-align: center; }
  .getstart-content i {
    font-size: 48px;
    color: #5d78ff; }
  .getstart-content h3, .getstart-content .h3 {
    margin-top: 15px;
    font-size: 20px; }

.promo .promo-content {
  text-align: center; }
  .promo .promo-content .promo-content-img {
    background: #5d78ff;
    height: 150px;
    width: 150px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px; }
    .promo .promo-content .promo-content-img img {
      max-width: 70px; }
  .promo .promo-content h3, .promo .promo-content .h3 {
    margin-bottom: 15px; }
  .promo .promo-content p {
    line-height: 30px; }

.portfolio_list .d-flex {
  margin-bottom: 30px; }
  .portfolio_list .d-flex h4, .portfolio_list .d-flex .h4 {
    font-size: 22px; }
  .portfolio_list .d-flex .port-icon {
    height: 65px;
    width: 140px;
    background: #131722;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    margin-right: 20px;
    color: #5d78ff; }
  .portfolio_list .d-flex p {
    margin-bottom: 0px; }
    .portfolio_list .d-flex p a {
      color: #5d78ff; }

.portfolio_img img {
  border-radius: 5px;
  box-shadow: 25px 60px 125px -25px rgba(0, 0, 0, 0.2), 16px 40px 75px -40px rgba(0, 0, 0, 0.2); }

.trade-app-content {
  border: 0px;
  background: #131722;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1); }
  .trade-app-content .card-body {
    padding: 20px 30px; }
    .trade-app-content .card-body span {
      font-size: 48px;
      margin-bottom: 15px;
      display: inline-block;
      color: #5d78ff; }
    .trade-app-content .card-body h4, .trade-app-content .card-body .h4 {
      font-size: 20px;
      margin-bottom: 15px; }
    .trade-app-content .card-body p {
      min-height: 115px; }
      @media only screen and (max-width: 1199px) {
        .trade-app-content .card-body p {
          min-height: 165px; } }
      @media only screen and (max-width: 991px) {
        .trade-app-content .card-body p {
          min-height: auto; } }
    .trade-app-content .card-body a {
      color: #5d78ff;
      display: flex;
      font-weight: 500;
      align-items: center; }
      .trade-app-content .card-body a i {
        margin-left: 15px;
        font-size: 18px;
        font-weight: bold; }

.our_vision h3, .our_vision .h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 28px; }

.our_vision p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px; }

.service-img img {
  border-radius: 5px; }

.profile_card .media {
  border-bottom: 1px solid #363C4E;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.card-profile__info h5, .card-profile__info .h5 {
  display: inline-block; }

.social-icons {
  margin-top: 20px; }
  .social-icons a {
    border-radius: 100px;
    padding: 7px 0px;
    margin-right: 15px;
    height: 42px;
    width: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #5d78ff;
    text-align: center; }

.acc_balance .btn-group {
  width: 100%; }

.linked_account .media {
  align-items: center;
  border-radius: 5px; }
  .linked_account .media > span {
    background: #5d78ff;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px; }
  .linked_account .media .media-body p {
    margin-bottom: 0px; }

.linked_account .edit-option a {
  display: inline-block;
  padding: 5px;
  font-size: 18px; }
  .linked_account .edit-option a:last-child {
    color: #E50202; }

.linked_account .verify .verified,
.linked_account .verify .not-verify {
  display: flex;
  align-items: center;
  font-weight: 500; }
  .linked_account .verify .verified span,
  .linked_account .verify .not-verify span {
    background: #10d876;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 15px;
    font-weight: bold; }

.linked_account .verify .not-verify span {
  background: #E50202; }

#deposits .nav-tabs {
  border-color: #363C4E; }
  #deposits .nav-tabs li a {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #a1a0a7;
    border-color: #363C4E; }
    #deposits .nav-tabs li a.active {
      background: #5d78ff;
      color: #fff;
      border-bottom: 1px solid #5d78ff; }

#deposits .tab-content .qrcode {
  text-align: center;
  margin-top: 50px;
  padding: 20px 0; }

#deposits .tab-content form {
  max-width: 750px;
  margin: 30px auto 50px; }

#deposits .tab-content ul {
  max-width: 750px; }
  #deposits .tab-content ul li {
    padding-left: 25px;
    position: relative;
    padding-bottom: 7px; }
    #deposits .tab-content ul li i {
      font-size: 16px;
      margin-right: 7px;
      position: absolute;
      left: 0; }

.important-info ul {
  max-width: 850px; }
  .important-info ul li {
    padding-left: 25px;
    position: relative;
    padding-bottom: 7px; }
    .important-info ul li i {
      font-size: 16px;
      margin-right: 7px;
      position: absolute;
      left: 0; }

.authenication {
  height: 100%; }

.auth-form {
  border: 0px solid #363C4E; }
  .auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px; }

.verification {
  height: 100%; }

.identity-content {
  text-align: center; }
  .identity-content .icon {
    display: flex;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    margin: 0 auto;
    background: #10d876;
    color: #097942;
    font-size: 30px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px; }
  .identity-content h4, .identity-content .h4 {
    max-width: 350px;
    margin: 0 auto 15px; }
  .identity-content p {
    max-width: 350px;
    margin: 0 auto 30px; }

.blog-grid .card {
  border: 0px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  margin-bottom: 30px;
  background: #131722; }
  .blog-grid .card img {
    max-height: 185px;
    width: 100%; }
    @media only screen and (max-width: 1199px) {
      .blog-grid .card img {
        max-height: 150px; } }
    @media only screen and (max-width: 991px) {
      .blog-grid .card img {
        max-height: 100%; } }
  .blog-grid .card .card-body {
    padding: 20px !important; }
    .blog-grid .card .card-body .card-title {
      font-size: 20px;
      margin-bottom: 15px; }
  .blog-grid .card .card-footer {
    padding: 10px 30px 30px;
    background: #131722;
    border: 0px; }
    .blog-grid .card .card-footer .meta-info {
      display: flex;
      justify-content: space-between; }
      .blog-grid .card .card-footer .meta-info .author img {
        max-width: 30px;
        border-radius: 50px;
        margin-right: 7px; }
      .blog-grid .card .card-footer .meta-info a {
        color: #a1a0a7;
        font-size: 14px; }
        .blog-grid .card .card-footer .meta-info a:hover {
          color: #5d78ff; }

.blog-sidebar .widget-search {
  position: relative; }
  .blog-sidebar .widget-search input {
    color: #677294;
    border-radius: 5px;
    background: #131722;
    border: 1px solid #363C4E;
    padding-left: 30px;
    height: 55px;
    z-index: 0; }
  .blog-sidebar .widget-search span {
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    color: #282835;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 18px;
    padding: 0px;
    z-index: 1;
    cursor: pointer; }

.blog-sidebar .widget-recent-post {
  margin-top: 50px; }
  .blog-sidebar .widget-recent-post .post-title {
    font-size: 20px;
    border-bottom: 1px solid #363C4E;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-recent-post .media img {
    max-width: 60px;
    border-radius: 5px; }
  .blog-sidebar .widget-recent-post .media h5, .blog-sidebar .widget-recent-post .media .h5 {
    font-size: 16px; }
  .blog-sidebar .widget-recent-post .meta-info a {
    font-size: 14px;
    color: #a1a0a7;
    margin-right: 5px; }

.blog-sidebar .widget-category {
  margin-top: 50px; }
  .blog-sidebar .widget-category .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #363C4E;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-category .list-group-item {
    background: #131722;
    border: 0px;
    color: #a1a0a7; }
    .blog-sidebar .widget-category .list-group-item .badge {
      background: #5d78ff;
      border-radius: 5px;
      padding: 5px 7px; }

.blog-sidebar .widget-tag {
  margin-top: 50px; }
  .blog-sidebar .widget-tag .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #363C4E;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-tag .tag-group a {
    color: #a1a0a7;
    border: 1px solid #363C4E;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin: 5px;
    font-size: 14px; }

.blog-single-details {
  float: left;
  width: 100%; }

.blog-single-post {
  float: left;
  width: 100%;
  cursor: pointer;
  position: relative; }
  .blog-single-post > ul.post-nfo {
    float: left;
    width: 100%;
    margin-bottom: 7px;
    margin-top: -1px; }

.post_info > ul.post-nfo {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  margin-top: -1px; }

.post-nfo li {
  display: inline-block;
  margin-right: 20px; }
  .post-nfo li a {
    display: inline-block;
    color: #a1a0a7;
    font-size: 16px;
    margin-right: 20px;
    margin-right: 0; }
  .post-nfo li i {
    font-size: 16px;
    padding-right: 6px;
    position: relative;
    top: 1px; }

.pt {
  padding: 105px 0; }

.blog-single-sec.section-padding {
  padding: 110px 0 110px 0; }

.blog-single-post > h3, .blog-single-post > .h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%; }

.post_info > h3, .post_info > .h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%; }

.blog-img {
  float: left;
  width: 100%;
  margin-bottom: 34px;
  position: relative; }
  .blog-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: rgba(127, 142, 233, 0.85);
    opacity: 0;
    visibility: hidden; }

.agent_img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background-color: rgba(127, 142, 233, 0.85);
  opacity: 0;
  visibility: hidden; }

.blog-single-post:hover .blog-img:before,
.agent:hover .agent_img:before {
  opacity: 1;
  visibility: visible;
  left: 0;
  width: 100%; }

.blog-single-post.single {
  cursor: inherit; }
  .blog-single-post.single .blog-img:before, .blog-single-post.single .blog-img:after {
    display: none; }

.blog-img > a img {
  width: 100%;
  float: left; }

.blog-single-post p {
  float: left;
  width: 100%;
  margin-bottom: 24px; }

.blog-single-post > blockquote {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  border-left: 3px solid;
  padding: 2px 0 1px 18px;
  float: left;
  width: 100%;
  margin-bottom: 38px;
  margin-top: 13px; }

.blg-dv {
  float: left;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 8px; }

.blg-sm {
  float: left;
  width: 100%; }

.blg-info {
  float: left;
  width: 100%;
  padding-left: 27px; }

.bg-links {
  float: left;
  width: 100%;
  padding: 0 40px;
  margin-top: 5px;
  margin-bottom: 13px; }
  .bg-links li {
    float: left;
    width: 100%;
    color: #fff;
    margin-bottom: 17px;
    position: relative;
    padding-left: 19px; }
    .bg-links li :before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 100px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }

/* ================ post-share =============== */
.post-share {
  float: left;
  width: 100%;
  padding: 21px 0;
  border-top: 1px solid #363C4E;
  margin-top: 8px;
  margin-bottom: 27px; }

.social-links {
  float: left; }
  .social-links li {
    display: inline-block;
    margin-right: 17px; }
    .social-links li a {
      display: inline-block;
      color: #5d78ff;
      font-size: 14px; }

.post-share > a {
  float: right;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px; }
  .post-share > a i {
    padding-left: 8px; }

/* ============== cm-info-sec ================ */
.cm-info-sec {
  float: left;
  width: 100%;
  display: table;
  border: 1px solid #363C4E;
  padding: 29px 24px;
  margin-bottom: 68px; }

.cm-img {
  display: table-cell; }
  .cm-img > img {
    float: left; }

.cm-info {
  display: table-cell;
  width: 100%;
  vertical-align: top;
  padding-left: 19px;
  position: relative;
  top: -1px; }
  .cm-info h3, .cm-info .h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px; }
  .cm-info > p {
    line-height: 24px;
    margin-bottom: 12px; }

/* ================= comment-section ================  */
.comment-section {
  float: left;
  width: 100%;
  margin-top: 7px; }

.p-title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px; }

.comment-section .cm-info-sec {
  padding: 0;
  border: 0;
  margin: 0; }

.comment-section .cm-info {
  padding-top: 7px; }
  .comment-section .cm-info > h4, .comment-section .cm-info > .h4 {
    color: #a1a0a7;
    font-size: 14px;
    font-weight: 400; }
  .comment-section .cm-info h3, .comment-section .cm-info .h3 {
    margin-bottom: 3px; }

.comment-section > ul {
  float: left;
  width: 100%; }
  .comment-section > ul > li {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 32px; }

.comment-section ul li p {
  float: left;
  width: 100%;
  margin-top: 13px; }

.cm-reply {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 7px;
  right: 0;
  text-transform: uppercase;
  letter-spacing: 1px; }

/* ================ post-comment-sec =============== */
.post-comment-sec {
  float: left;
  width: 100%;
  margin-top: 22px; }
  .post-comment-sec .p-title {
    margin-bottom: 18px; }
  .post-comment-sec > form {
    float: left;
    width: 100%; }
  .post-comment-sec .col-lg-4 {
    padding: 0 8px; }

.buy-sell-widget .nav-tabs {
  margin-bottom: 30px;
  border: 0px; }
  .buy-sell-widget .nav-tabs .nav-item {
    width: 50%;
    text-align: center; }
    .buy-sell-widget .nav-tabs .nav-item .nav-link {
      padding: 10px 10px;
      font-size: 18px;
      text-transform: uppercase;
      border: 0px;
      font-weight: 500;
      border-radius: 5px;
      background: transparent;
      border: 1px solid #363C4E;
      margin: 0px 15px; }
      .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
        background: #5d78ff;
        color: white; }

@media only screen and (max-width: 767px) {
  .buyer-seller .d-flex .buyer-info,
  .buyer-seller .d-flex .seller-info {
    padding: 10px; }
    .buyer-seller .d-flex .buyer-info img,
    .buyer-seller .d-flex .seller-info img {
      display: none; } }

@media only screen and (max-width: 767px) {
  .buyer-seller .d-flex {
    justify-content: flex-start; } }

@media only screen and (max-width: 575px) {
  .buyer-seller .d-flex {
    flex-direction: column; }
    .buyer-seller .d-flex .seller-info {
      text-align: left !important; } }

#accordion-faq {
  margin-top: -15px;
  box-shadow: none; }
  #accordion-faq .card {
    margin-bottom: 0px;
    border: 0px solid #363C4E;
    box-shadow: none; }
    #accordion-faq .card .card-header {
      border: 0px;
      padding: 15px 0px 10px; }
    #accordion-faq .card .card-body {
      padding: 0px 0px 10px; }

.intro-video-play {
  background-color: #fff;
  background-image: url("./../images/background/2.jpg");
  background-size: cover;
  background-position: 0 0;
  min-height: 363px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  z-index: 0;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1); }
  .intro-video-play::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #5d78ff;
    opacity: 0.45;
    border-radius: 5px;
    z-index: -1; }

.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }
  .play-btn a {
    background: #5d78ff;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    padding: 27px 0;
    text-align: center;
    cursor: pointer; }
    .play-btn a i {
      color: #fff;
      font-size: 20px; }

.apps-download {
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
  padding: 0px 20px; }
  .apps-download ::before {
    background-image: url("./../images/app.png");
    background-color: #3A3361;
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    opacity: 0.15; }
  .apps-download ::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #5d78ff;
    opacity: 0.3;
    z-index: -1;
    border-radius: 5px; }

.apps-download-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 504px; }
  .apps-download-content h4, .apps-download-content .h4 {
    padding: 20px;
    color: #fff;
    text-align: center;
    line-height: 1.3; }
  .apps-download-content .btn {
    min-width: 130px; }
  .apps-download-content .btn-primary {
    background: #2a4eff; }

.transaction-table td {
  padding: 1.25rem 0.75rem; }

.transaction-table i.cc {
  font-size: 22px;
  margin-right: 10px; }

.transaction-table .sold-thumb {
  background: #E50202;
  color: #fff;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .transaction-table .sold-thumb i {
    font-weight: 700; }

.transaction-table .buy-thumb {
  background: #10d876;
  color: #fff;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .transaction-table .buy-thumb i {
    font-weight: 700; }

.transaction-table .badge {
  color: #fff; }

.info-list ul li {
  margin-bottom: 7px;
  color: #a1a0a7;
  font-weight: 500;
  padding: 12px 0; }
  .info-list ul li:hover, .info-list ul li:focus {
    color: #5d78ff; }
  .info-list ul li i {
    margin-right: 15px;
    color: #5d78ff;
    font-weight: bold;
    font-size: 24px; }

@media only screen and (max-width: 767px) {
  .sub-menu ul {
    display: inline-block !important; } }

.sub-menu ul li a {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  color: #a1a0a7; }
  .sub-menu ul li a:hover, .sub-menu ul li a:focus, .sub-menu ul li a:active, .sub-menu ul li a.active {
    color: #5d78ff;
    background-color: transparent; }

.sub-menu ul li i {
  font-weight: bold;
  margin-right: 7px;
  font-size: 20px; }

.sub-menu ul li span {
  font-weight: 400; }

.phone_verify {
  margin-top: 50px;
  border-top: 1px solid #363C4E;
  padding-top: 30px; }

.phone_verified {
  display: flex;
  align-items: center; }
  .phone_verified h5, .phone_verified .h5 {
    margin-bottom: 0;
    margin-right: 30px;
    color: #a1a0a7; }
    .phone_verified h5 span, .phone_verified .h5 span {
      color: #5d78ff;
      padding: 9px 10px;
      border-radius: 50px;
      margin-right: 7px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid #363C4E;
      height: 40px;
      width: 40px;
      display: inline-block; }
  .phone_verified .verified,
  .phone_verified .not-verify {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px; }
    .phone_verified .verified span,
    .phone_verified .not-verify span {
      background: #10d876;
      color: #fff;
      padding: 10px;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
      font-size: 12px; }
  .phone_verified .not-verify span {
    background: #E50202; }

.id_card {
  padding: 20px;
  border: 1px solid #363C4E;
  border-radius: 5px;
  margin: 20px 0px; }

.price-grid .card {
  border: 1px solid #363C4E; }
  .price-grid .card .card-header {
    padding: 15px 15px 0;
    border: 0px; }
    .price-grid .card .card-header .media {
      align-items: center; }
    .price-grid .card .card-header .media-body {
      color: #fff; }
    .price-grid .card .card-header i {
      margin-right: 10px;
      font-size: 30px; }
    .price-grid .card .card-header p {
      font-size: 14px; }
  .price-grid .card .card-body {
    padding: 15px 15px 0px !important; }
    .price-grid .card .card-body h3, .price-grid .card .card-body .h3 {
      font-size: 20px;
      font-weight: 500; }

.coin-search {
  padding-bottom: 70px; }
  .coin-search .search-box form {
    display: flex; }
    .coin-search .search-box form .btn {
      margin-left: 15px;
      padding: 8px 30px; }

.sort-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px; }
  .sort-view .search-timeline a {
    color: #fff;
    font-size: 18px;
    display: inline-block;
    margin-right: 15px;
    font-weight: 500; }
    .sort-view .search-timeline a:hover, .sort-view .search-timeline a:focus, .sort-view .search-timeline a:active, .sort-view .search-timeline a.active {
      color: #5d78ff; }
  .sort-view .coin-view .nav {
    margin-bottom: 0px; }
    .sort-view .coin-view .nav .nav-item:last-child .nav-link {
      margin-right: 0px; }
    .sort-view .coin-view .nav .nav-item .nav-link {
      background: rgba(0, 0, 0, 0.15);
      color: #5d78ff;
      margin-right: 10px; }
      .sort-view .coin-view .nav .nav-item .nav-link:hover, .sort-view .coin-view .nav .nav-item .nav-link:focus, .sort-view .coin-view .nav .nav-item .nav-link:active, .sort-view .coin-view .nav .nav-item .nav-link.active {
        background: #5d78ff;
        color: #fff; }

.terms_condition {
  position: relative; }

.terms_condition-content {
  padding: 80px 0px 80px; }

.terms_condition-text {
  margin-bottom: 45px; }
  .terms_condition-text:last-child {
    margin-bottom: 0; }
  .terms_condition-text h3, .terms_condition-text .h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px; }
  .terms_condition-text p {
    font-size: 16px;
    line-height: 30px;
    display: inline-block; }
  .terms_condition-text li {
    display: flex; }
    .terms_condition-text li i {
      line-height: 30px;
      margin-right: 15px;
      font-size: 13px;
      color: #5d78ff; }

.wallet-feature {
  border-top: 1px solid #363C4E; }
  .wallet-feature .wallet-feature-content {
    margin-bottom: 50px;
    text-align: center; }
    .wallet-feature .wallet-feature-content i {
      font-size: 40px;
      margin-bottom: 20px;
      color: #5d78ff; }
    .wallet-feature .wallet-feature-content h4, .wallet-feature .wallet-feature-content .h4 {
      max-width: 200px;
      margin: 0 auto; }

.all-coin {
  border-bottom: 1px solid #363C4E; }

.all-coins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .all-coins span {
    font-size: 48px;
    width: 100px;
    height: 100px;
    border: 1px solid #363C4E;
    border-radius: 100%;
    text-align: center;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }

.all-coin-content {
  padding: 75px 0 0;
  text-align: center; }
  .all-coin-content h3, .all-coin-content .h3 {
    margin-bottom: 15px; }

.join-team {
  border-bottom: 1px solid #363C4E; }

.interested-join {
  border-top: 1px solid #363C4E;
  background: #2a4eff; }
  .interested-join h2, .interested-join .h2 {
    color: #fff;
    margin-bottom: 15px; }
  .interested-join p {
    color: #fff; }
  .interested-join .btn {
    padding: 7px 20px;
    margin-top: 30px; }

.career .career-content-img img {
  border-radius: 5px; }

.career .career-content h3, .career .career-content .h3 {
  font-weight: 500;
  margin-bottom: 15px; }

.career .career-content p {
  line-height: 1.75; }

.helpdesk-search {
  background: rgba(0, 0, 0, 0.15); }
  .helpdesk-search .helpdesk-search-content {
    text-align: center; }
  .helpdesk-search .helpdesk-form form {
    display: flex; }
  .helpdesk-search .helpdesk-form .btn {
    margin-left: 20px;
    padding: 6px 30px; }

.help-category .help-cat {
  text-align: center;
  margin-bottom: 50px; }
  .help-category .help-cat span {
    font-size: 48px;
    color: #5d78ff; }
  .help-category .help-cat h4, .help-category .help-cat .h4 {
    font-size: 20px;
    margin-top: 15px; }

.help-category .sub-cat {
  display: inline-block;
  padding: 20px 30px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px; }
  .help-category .sub-cat h5, .help-category .sub-cat .h5 {
    color: #5d78ff;
    margin-bottom: 0px; }

.troubleshooting-content h4, .troubleshooting-content .h4 {
  border-bottom: 1px solid #363C4E;
  padding-bottom: 20px;
  margin-top: 50px; }

.troubleshooting-content ul {
  margin-top: 20px; }
  .troubleshooting-content ul li a {
    color: #a1a0a7;
    display: inline-block;
    padding-bottom: 10px; }
    .troubleshooting-content ul li a:hover, .troubleshooting-content ul li a:focus, .troubleshooting-content ul li a:active, .troubleshooting-content ul li a.active {
      color: #5d78ff; }

.ticket-box-content {
  background: rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 50px 15px;
  border-radius: 5px; }
  .ticket-box-content p {
    padding: 10px 0 20px;
    margin-bottom: 0px;
    font-size: 18px; }
  .ticket-box-content .btn {
    min-width: 150px;
    font-weight: 500; }

.header {
  padding: 10px 0px;
  background: #131722;
  border-bottom: 0px solid #363C4E;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0; }
  .header.fixed {
    padding: 10px 0px;
    box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    border-color: transparent; }
  .header .navigation .navbar {
    background-color: transparent !important;
    padding: 0px; }
    .header .navigation .navbar .collapse {
      justify-content: flex-end; }
    .header .navigation .navbar .navbar-nav {
      align-items: center; }
      @media only screen and (max-width: 991px) {
        .header .navigation .navbar .navbar-nav {
          margin-top: 15px; } }
      .header .navigation .navbar .navbar-nav > .nav-item {
        display: inline-block;
        padding: 7px;
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .header .navigation .navbar .navbar-nav > .nav-item {
            border-bottom: 1px solid #363C4E;
            margin: 0 15px;
            padding: 7px 15px; } }
        .header .navigation .navbar .navbar-nav > .nav-item > a {
          font-size: 16px;
          font-weight: 500;
          color: #a1a0a7; }
          .header .navigation .navbar .navbar-nav > .nav-item > a :hover, .header .navigation .navbar .navbar-nav > .nav-item > a:focus, .header .navigation .navbar .navbar-nav > .nav-item > a.active {
            color: #5d78ff; }
          @media only screen and (max-width: 991px) {
            .header .navigation .navbar .navbar-nav > .nav-item > a::after {
              position: absolute;
              right: 15px;
              top: 25px; } }
      @media only screen and (min-width: 991px) {
        .header .navigation .navbar .navbar-nav .dropdown > a {
          position: relative;
          transition: all 0.2s ease-in-out; }
        .header .navigation .navbar .navbar-nav .dropdown-menu {
          background: rgba(0, 0, 0, 0.15);
          padding: 15px 0;
          display: block;
          opacity: 0;
          visibility: hidden;
          z-index: 1030;
          transition: all 0.2s ease-in-out;
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
          border: 0px;
          margin-top: 10px;
          z-index: 998;
          min-width: 10rem; }
          .header .navigation .navbar .navbar-nav .dropdown-menu a {
            display: inline-block;
            color: #a1a0a7; }
            .header .navigation .navbar .navbar-nav .dropdown-menu a:hover, .header .navigation .navbar .navbar-nav .dropdown-menu a:focus, .header .navigation .navbar .navbar-nav .dropdown-menu a.active {
              background: rgba(0, 0, 0, 0.1);
              color: #fff; }
        .header .navigation .navbar .navbar-nav .nav-item.dropdown:hover a::before {
          opacity: 1;
          bottom: -9px; }
        .header .navigation .navbar .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
          opacity: 1;
          visibility: visible;
          margin-top: 0px; } }
      @media only screen and (max-width: 991px) {
        .header .navigation .navbar .navbar-nav .dropdown-menu {
          border: 0px;
          padding: 0px;
          background: rgba(0, 0, 0, 0.15); }
          .header .navigation .navbar .navbar-nav .dropdown-menu a {
            border-bottom: 1px solid #363C4E;
            padding: 15px;
            color: #a1a0a7; }
            .header .navigation .navbar .navbar-nav .dropdown-menu a :last-child {
              border: 0px; }
            .header .navigation .navbar .navbar-nav .dropdown-menu a i,
            .header .navigation .navbar .navbar-nav .dropdown-menu a h6,
            .header .navigation .navbar .navbar-nav .dropdown-menu a .h6 {
              color: #5d78ff; }
            .header .navigation .navbar .navbar-nav .dropdown-menu a p {
              color: #a1a0a7;
              margin-bottom: 0; } }

.signin-btn .btn {
  padding: 7px 15px !important;
  min-width: 85px !important; }

@media only screen and (max-width: 991px) {
  .signin-btn {
    position: absolute;
    right: 73px;
    top: 3px; } }

.header.dashboard {
  padding: 5px 10px;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08); }
  .header.dashboard .navbar {
    padding: 0px; }

.header .navbar-brand {
  display: flex;
  align-items: center; }
  .header .navbar-brand img {
    max-width: 115px; }
  .header .navbar-brand span {
    color: #131722;
    font-weight: 500;
    margin-left: 20px;
    font-size: 24px; }

.header_auth .btn {
  margin-left: 10px; }
  @media only screen and (max-width: 991px) {
    .header_auth .btn {
      padding: 7px;
      min-width: 110px; } }
  @media only screen and (max-width: 991px) {
    .header_auth .btn {
      display: none; } }

.navbar-toggler {
  background: rgba(255, 255, 255, 0.2);
  min-width: auto; }

@media only screen and (max-width: 991px) {
  .navbar-nav {
    margin-top: 10px;
    border-radius: 5px; } }

.navbar-nav .nav-item .nav-link {
  color: #fff;
  font-weight: 500;
  opacity: 0.75; }
  .navbar-nav .nav-item .nav-link:hover, .navbar-nav .nav-item .nav-link:focus, .navbar-nav .nav-item .nav-link.active {
    opacity: 1;
    color: #5d78ff; }

.language {
  margin-right: 20px; }
  .language .icon {
    cursor: pointer; }
    .language .icon i {
      margin-right: 7px; }
  .language .dropdown-menu {
    border: 0px;
    padding: 5px 0px;
    margin: 0px;
    top: 42px;
    min-width: 100px;
    box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);
    border-radius: 5px;
    background-color: #131722; }
    .language .dropdown-menu .dropdown-item {
      border-top: 1px solid #363C4E;
      color: #a1a0a7; }
      .language .dropdown-menu .dropdown-item:first-child {
        border: 0px; }
      .language .dropdown-menu .dropdown-item:hover, .language .dropdown-menu .dropdown-item:focus, .language .dropdown-menu .dropdown-item.active {
        background-color: transparent; }
    .language .dropdown-menu a {
      padding: 5px 20px; }

.profile_log {
  cursor: pointer; }
  .profile_log .user {
    display: flex;
    align-items: center; }
    .profile_log .user .thumb {
      height: 32px;
      width: 32px;
      border-radius: 50px;
      border: 1px solid #5d78ff;
      display: inline-block;
      margin-right: 7px;
      background: #5d78ff;
      color: #fff;
      text-align: center;
      padding: 3px 0; }
      .profile_log .user .thumb i {
        font-size: 18px;
        line-height: 1.3; }
    .profile_log .user .arrow i {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-top: 6px;
      display: inline-block; }
    @media only screen and (max-width: 1199px) {
      .profile_log .user .arrow {
        display: none; } }
  .profile_log .dropdown-menu {
    border: 0px;
    padding: 0px;
    margin: 0px;
    top: 47px;
    box-shadow: 0 10px 20px rgba(92, 119, 252, 0.15);
    border-radius: 5px;
    background-color: #131722;
    min-width: 240px; }
    .profile_log .dropdown-menu .user-email {
      padding: 20px 20px 10px; }
      .profile_log .dropdown-menu .user-email h6, .profile_log .dropdown-menu .user-email .h6 {
        margin-bottom: 0px; }
    .profile_log .dropdown-menu .user-balance {
      display: flex;
      justify-content: space-around;
      margin-bottom: 15px; }
      .profile_log .dropdown-menu .user-balance p {
        margin-bottom: 0px;
        font-weight: 500;
        color: #fff; }
    .profile_log .dropdown-menu .dropdown-item {
      padding: 5px 20px;
      border-top: 1px solid #363C4E;
      font-size: 14px;
      color: #a1a0a7;
      font-weight: 400;
      display: flex;
      align-items: center; }
      .profile_log .dropdown-menu .dropdown-item:first-child {
        border: 0px; }
      .profile_log .dropdown-menu .dropdown-item.logout {
        color: #E50202; }
        .profile_log .dropdown-menu .dropdown-item.logout i {
          color: #E50202; }
      .profile_log .dropdown-menu .dropdown-item i {
        margin-right: 10px;
        font-size: 22px;
        color: #5d78ff;
        font-weight: bold; }
      .profile_log .dropdown-menu .dropdown-item:hover, .profile_log .dropdown-menu .dropdown-item:focus, .profile_log .dropdown-menu .dropdown-item.active {
        background-color: transparent; }

.page-title {
  padding: 30px 0; }
  .page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px; }
  .page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px; }
    .page-title .page-title-content p span {
      font-weight: 500;
      color: #5d78ff; }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 575px) {
    .breadcrumbs {
      justify-content: flex-start; } }
  .breadcrumbs li a {
    color: #AEAED5;
    display: inline-block;
    margin-left: 15px;
    font-size: 14px; }
  .breadcrumbs li:first-child a {
    margin-left: 0px; }
  .breadcrumbs li.active a {
    color: #5d78ff; }

.sidebar {
  background: #131722;
  position: fixed;
  left: 0;
  height: 100%;
  width: 60px;
  top: 0;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08); }

.menu {
  margin-top: 100px; }
  .menu ul li {
    text-align: center;
    margin-bottom: 15px; }
    .menu ul li a {
      padding: 2px 10px;
      display: inline-block;
      margin-bottom: 5px;
      border-radius: 5px; }
      .menu ul li a:hover, .menu ul li a:focus, .menu ul li a:active {
        background: #5d78ff; }
        .menu ul li a:hover i, .menu ul li a:focus i, .menu ul li a:active i {
          color: #fff; }
      .menu ul li a.active {
        background: #5d78ff; }
        .menu ul li a.active i {
          color: #fff; }
      .menu ul li a i {
        color: #fff;
        font-size: 20px; }

.bottom {
  background: #131722; }
  .bottom .bottom-logo p {
    line-height: 30px;
    font-weight: 400; }
  @media only screen and (max-width: 991px) {
    .bottom .bottom-widget {
      margin-bottom: 30px; } }
  .bottom .bottom-widget .widget-title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px; }
  .bottom .bottom-widget ul li a {
    color: #a1a0a7;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block; }

.footer {
  border-top: 1px solid #14203b;
  background: #131722;
  padding: 10px 0px; }
  .footer.dashboard {
    background: #131722;
    border-color: #363C4E;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0; }
  .footer .copyright p {
    margin: 0;
    font-size: 14px;
    color: #7f88a6; }
    .footer .copyright p a {
      color: #5d78ff;
      font-weight: 500; }
    @media only screen and (max-width: 575px) {
      .footer .copyright p {
        text-align: center; } }
  .footer .footer-social {
    text-align: right; }
    @media only screen and (max-width: 575px) {
      .footer .footer-social {
        text-align: center; } }
    .footer .footer-social li {
      display: inline-block; }
      .footer .footer-social li a {
        color: #7f88a6;
        padding: 0px 10px; }

.sidebar-right {
  display: block;
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px;
  margin-top: 5rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1); }
  .sidebar-right .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: #5d78ff;
    color: #fff;
    display: inline-block;
    height: 2.125rem;
    width: 2.125rem;
    text-align: center;
    font-size: 1.3125rem;
    line-height: 2.2rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .sidebar-right .sidebar-right-trigger:hover {
      color: #fff; }
  .sidebar-right.show {
    right: 0;
    z-index: 999; }
  .sidebar-right .admin-settings p {
    margin-bottom: 0.3125rem; }
  .sidebar-right .admin-settings input[type="radio"] {
    display: none; }
    .sidebar-right .admin-settings input[type="radio"] + label {
      display: inline-block;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 3px 8px;
      transition: all 0.1s ease;
      border-radius: 100px; }
    .sidebar-right .admin-settings input[type="radio"]:checked + label {
      position: relative; }
      .sidebar-right .admin-settings input[type="radio"]:checked + label::after {
        height: 12px;
        width: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 100px; }
  .sidebar-right #nav_header_color_1 + label {
    background-color: #10D078; }
  .sidebar-right #nav_header_color_2 + label {
    background-color: #FF0096; }
  .sidebar-right #nav_header_color_3 + label {
    background-color: #002EFF; }
  .sidebar-right #nav_header_color_4 + label {
    background-color: #FF2F00; }
  .sidebar-right #nav_header_color_5 + label {
    background-color: #00CCD3; }
  .sidebar-right #nav_header_color_1 + label {
    border: 1px solid #363C4E; }
  .sidebar-right #header_color_1 + label {
    background-color: #fff; }
  .sidebar-right #header_color_2 + label {
    background-color: #131722; }
  .sidebar-right #header_color_3 + label {
    background-color: #2D2B55; }
  .sidebar-right #header_color_4 + label {
    background-color: #30243D; }
  .sidebar-right #header_color_5 + label {
    background-color: #193549; }
  .sidebar-right #header_color_1 + label {
    border: 1px solid #363C4E; }

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); } }

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%); } }

[data-bodybg="color_2"] {
  background-color: #131722 !important;
  color: #7d8db7 !important; }
  [data-bodybg="color_2"] h1, [data-bodybg="color_2"] .h1,
  [data-bodybg="color_2"] h2,
  [data-bodybg="color_2"] .h2,
  [data-bodybg="color_2"] h3,
  [data-bodybg="color_2"] .h3,
  [data-bodybg="color_2"] h4,
  [data-bodybg="color_2"] .h4,
  [data-bodybg="color_2"] h5,
  [data-bodybg="color_2"] .h5,
  [data-bodybg="color_2"] h6,
  [data-bodybg="color_2"] .h6,
  [data-bodybg="color_2"] .h1,
  [data-bodybg="color_2"] .h2,
  [data-bodybg="color_2"] .h3,
  [data-bodybg="color_2"] .h4,
  [data-bodybg="color_2"] .h5,
  [data-bodybg="color_2"] .h6 {
    color: #e0e3ed; }
  [data-bodybg="color_2"] .sidebar,
  [data-bodybg="color_2"] .header {
    background: #181e2c; }
  [data-bodybg="color_2"] .card {
    background: #181e2c;
    border: 1px solid #252d43; }
    [data-bodybg="color_2"] .card-header {
      background: #181e2c;
      border-color: #11151f; }
    [data-bodybg="color_2"] .card .card-body {
      background: #181e2c; }
  [data-bodybg="color_2"] .group-2JyOhh7Z {
    background-color: #181e2c !important; }

[data-bodybg="color_3"] {
  background-color: #2D2B55 !important;
  color: #adabd4 !important; }
  [data-bodybg="color_3"] h1, [data-bodybg="color_3"] .h1,
  [data-bodybg="color_3"] h2,
  [data-bodybg="color_3"] .h2,
  [data-bodybg="color_3"] h3,
  [data-bodybg="color_3"] .h3,
  [data-bodybg="color_3"] h4,
  [data-bodybg="color_3"] .h4,
  [data-bodybg="color_3"] h5,
  [data-bodybg="color_3"] .h5,
  [data-bodybg="color_3"] h6,
  [data-bodybg="color_3"] .h6,
  [data-bodybg="color_3"] .h1,
  [data-bodybg="color_3"] .h2,
  [data-bodybg="color_3"] .h3,
  [data-bodybg="color_3"] .h4,
  [data-bodybg="color_3"] .h5,
  [data-bodybg="color_3"] .h6 {
    color: white; }
  [data-bodybg="color_3"] .sidebar,
  [data-bodybg="color_3"] .header {
    background: #32305f; }
  [data-bodybg="color_3"] .card {
    background: #32305f;
    border: 1px solid #3f3c77; }
    [data-bodybg="color_3"] .card-header {
      background: #32305f;
      border-color: #2b2952; }
    [data-bodybg="color_3"] .card .card-body {
      background: #32305f; }
  [data-bodybg="color_3"] .group-2JyOhh7Z {
    background-color: #32305f !important; }

[data-bodybg="color_4"] {
  background-color: #30243D !important;
  color: #af9cc4 !important; }
  [data-bodybg="color_4"] h1, [data-bodybg="color_4"] .h1,
  [data-bodybg="color_4"] h2,
  [data-bodybg="color_4"] .h2,
  [data-bodybg="color_4"] h3,
  [data-bodybg="color_4"] .h3,
  [data-bodybg="color_4"] h4,
  [data-bodybg="color_4"] .h4,
  [data-bodybg="color_4"] h5,
  [data-bodybg="color_4"] .h5,
  [data-bodybg="color_4"] h6,
  [data-bodybg="color_4"] .h6,
  [data-bodybg="color_4"] .h1,
  [data-bodybg="color_4"] .h2,
  [data-bodybg="color_4"] .h3,
  [data-bodybg="color_4"] .h4,
  [data-bodybg="color_4"] .h5,
  [data-bodybg="color_4"] .h6 {
    color: #fcfcfd; }
  [data-bodybg="color_4"] .sidebar,
  [data-bodybg="color_4"] .header {
    background: #382a47; }
  [data-bodybg="color_4"] .card {
    background: #382a47;
    border: 1px solid #49375d; }
    [data-bodybg="color_4"] .card-header {
      background: #382a47;
      border-color: #2d223a; }
    [data-bodybg="color_4"] .card .card-body {
      background: #382a47; }
  [data-bodybg="color_4"] .group-2JyOhh7Z {
    background-color: #382a47 !important; }

[data-bodybg="color_5"] {
  background-color: #193549 !important;
  color: #8ab7d7 !important; }
  [data-bodybg="color_5"] h1, [data-bodybg="color_5"] .h1,
  [data-bodybg="color_5"] h2,
  [data-bodybg="color_5"] .h2,
  [data-bodybg="color_5"] h3,
  [data-bodybg="color_5"] .h3,
  [data-bodybg="color_5"] h4,
  [data-bodybg="color_5"] .h4,
  [data-bodybg="color_5"] h5,
  [data-bodybg="color_5"] .h5,
  [data-bodybg="color_5"] h6,
  [data-bodybg="color_5"] .h6,
  [data-bodybg="color_5"] .h1,
  [data-bodybg="color_5"] .h2,
  [data-bodybg="color_5"] .h3,
  [data-bodybg="color_5"] .h4,
  [data-bodybg="color_5"] .h5,
  [data-bodybg="color_5"] .h6 {
    color: #fcfdfe; }
  [data-bodybg="color_5"] .sidebar,
  [data-bodybg="color_5"] .header {
    background: #1d3d54; }
  [data-bodybg="color_5"] .card {
    background: #1d3d54;
    border: 1px solid #26516f; }
    [data-bodybg="color_5"] .card-header {
      background: #1d3d54;
      border-color: #183245; }
    [data-bodybg="color_5"] .card .card-body {
      background: #1d3d54; }
  [data-bodybg="color_5"] .group-2JyOhh7Z {
    background-color: #1d3d54 !important; }

[data-primary-color="color_2"] .btn-primary {
  background-color: #FF0096;
  border-color: #FF0096; }
  [data-primary-color="color_2"] .btn-primary:hover, [data-primary-color="color_2"] .btn-primary:focus, [data-primary-color="color_2"] .btn-primary:active, [data-primary-color="color_2"] .btn-primary.active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none; }
  [data-primary-color="color_2"] .btn-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_2"] .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none; }

[data-primary-color="color_2"] .btn-outline-primary {
  background-color: transparent;
  color: #FF0096;
  border-color: #FF0096; }
  [data-primary-color="color_2"] .btn-outline-primary:hover, [data-primary-color="color_2"] .btn-outline-primary:focus, [data-primary-color="color_2"] .btn-outline-primary:active, [data-primary-color="color_2"] .btn-outline-primary.active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
    color: #fff; }
  [data-primary-color="color_2"] .btn-outline-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_2"] .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #cc0078;
    border-color: #cc0078;
    box-shadow: none;
    color: #fff; }

[data-primary-color="color_2"] .sidebar {
  background: #e60087; }

[data-primary-color="color_2"] i.la,
[data-primary-color="color_2"] i.fa,
[data-primary-color="color_2"] .text-primary {
  color: #FF0096 !important; }

[data-primary-color="color_2"] .bg-primary {
  background-color: #FF0096 !important; }

[data-primary-color="color_2"] .owl-next,
[data-primary-color="color_2"] .owl-prev {
  background-color: #cc0078 !important; }
  [data-primary-color="color_2"] .owl-next i,
  [data-primary-color="color_2"] .owl-prev i {
    color: #fff !important; }

[data-primary-color="color_2"] .intro-form button {
  background: #FF0096;
  color: #fff !important; }
  [data-primary-color="color_2"] .intro-form button i {
    color: #fff !important; }

[data-primary-color="color_2"] .intro-form-exchange .btn i {
  color: #fff !important; }

[data-primary-color="color_2"] .product-feature-box i {
  color: #fff !important; }

[data-primary-color="color_2"] .trust-content {
  border-color: #FF0096; }

[data-primary-color="color_2"] .footer a {
  color: #FF0096 !important; }

[data-primary-color="color_2"] .sort-view .search-timeline a:hover,
[data-primary-color="color_2"] .sort-view .search-timeline a:focus,
[data-primary-color="color_2"] .sort-view .search-timeline a:active,
[data-primary-color="color_2"] .sort-view .search-timeline a.active {
  color: #FF0096; }

[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff; }

[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color="color_2"] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #FF0096; }

[data-primary-color="color_2"] .badge {
  background: #FF0096 !important;
  color: #fff !important; }

[data-primary-color="color_2"] .intro-video-play::after {
  background: #FF0096; }

[data-primary-color="color_2"] .menu ul li a,
[data-primary-color="color_2"] .menu ul li a i,
[data-primary-color="color_2"] .menu ul li a.active i {
  color: #fff !important; }

[data-primary-color="color_2"] .page-title .page-title-content p {
  color: #FF0096; }

[data-primary-color="color_2"] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color="color_2"] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color="color_2"] .profile_chart.card .card-header .duration-option a.active {
  background: #FF0096; }

[data-primary-color="color_2"] .profile_log .user .thumb {
  background: #FF0096;
  color: #fff !important; }
  [data-primary-color="color_2"] .profile_log .user .thumb i {
    color: #fff !important; }

[data-primary-color="color_2"] .trade-app-content .card-body a {
  color: #FF0096; }

[data-primary-color="color_2"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #FF0096; }

[data-primary-color="color_2"] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #FF0096;
  color: white; }

[data-primary-color="color_2"] .social-icons a {
  background: #FF0096;
  color: white !important; }
  [data-primary-color="color_2"] .social-icons a i {
    color: white !important; }

[data-primary-color="color_2"] .settings_menu ul li a {
  color: #FF0096; }

[data-primary-color="color_2"] .toggle-checkbox:checked + .toggle-switch {
  background: #FF0096; }

[data-primary-color="color_2"] .file-upload-wrapper:before {
  background: #FF0096; }

[data-primary-color="color_2"] .sidebar-right .sidebar-right-trigger {
  background-color: #FF0096 !important;
  color: #fff !important; }
  [data-primary-color="color_2"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important; }

[data-primary-color="color_2"] .form-control:focus,
[data-primary-color="color_2"] .form-control:active,
[data-primary-color="color_2"] .form-control.active {
  border-color: #FF0096; }

[data-primary-color="color_3"] .btn-primary {
  background-color: #002EFF;
  border-color: #002EFF; }
  [data-primary-color="color_3"] .btn-primary:hover, [data-primary-color="color_3"] .btn-primary:focus, [data-primary-color="color_3"] .btn-primary:active, [data-primary-color="color_3"] .btn-primary.active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none; }
  [data-primary-color="color_3"] .btn-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_3"] .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none; }

[data-primary-color="color_3"] .btn-outline-primary {
  background-color: transparent;
  color: #002EFF;
  border-color: #002EFF; }
  [data-primary-color="color_3"] .btn-outline-primary:hover, [data-primary-color="color_3"] .btn-outline-primary:focus, [data-primary-color="color_3"] .btn-outline-primary:active, [data-primary-color="color_3"] .btn-outline-primary.active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
    color: #fff; }
  [data-primary-color="color_3"] .btn-outline-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_3"] .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #0025cc;
    border-color: #0025cc;
    box-shadow: none;
    color: #fff; }

[data-primary-color="color_3"] .sidebar {
  background: #0029e6; }

[data-primary-color="color_3"] i.la,
[data-primary-color="color_3"] i.fa,
[data-primary-color="color_3"] .text-primary {
  color: #002EFF !important; }

[data-primary-color="color_3"] .bg-primary {
  background-color: #002EFF !important; }

[data-primary-color="color_3"] .owl-next,
[data-primary-color="color_3"] .owl-prev {
  background-color: #0025cc !important; }
  [data-primary-color="color_3"] .owl-next i,
  [data-primary-color="color_3"] .owl-prev i {
    color: #fff !important; }

[data-primary-color="color_3"] .intro-form button {
  background: #002EFF;
  color: #fff !important; }
  [data-primary-color="color_3"] .intro-form button i {
    color: #fff !important; }

[data-primary-color="color_3"] .intro-form-exchange .btn i {
  color: #fff !important; }

[data-primary-color="color_3"] .product-feature-box i {
  color: #fff !important; }

[data-primary-color="color_3"] .trust-content {
  border-color: #002EFF; }

[data-primary-color="color_3"] .footer a {
  color: #002EFF !important; }

[data-primary-color="color_3"] .sort-view .search-timeline a:hover,
[data-primary-color="color_3"] .sort-view .search-timeline a:focus,
[data-primary-color="color_3"] .sort-view .search-timeline a:active,
[data-primary-color="color_3"] .sort-view .search-timeline a.active {
  color: #002EFF; }

[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff; }

[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color="color_3"] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #002EFF; }

[data-primary-color="color_3"] .badge {
  background: #002EFF !important;
  color: #fff !important; }

[data-primary-color="color_3"] .intro-video-play::after {
  background: #002EFF; }

[data-primary-color="color_3"] .menu ul li a,
[data-primary-color="color_3"] .menu ul li a i,
[data-primary-color="color_3"] .menu ul li a.active i {
  color: #fff !important; }

[data-primary-color="color_3"] .page-title .page-title-content p {
  color: #002EFF; }

[data-primary-color="color_3"] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color="color_3"] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color="color_3"] .profile_chart.card .card-header .duration-option a.active {
  background: #002EFF; }

[data-primary-color="color_3"] .profile_log .user .thumb {
  background: #002EFF;
  color: #fff !important; }
  [data-primary-color="color_3"] .profile_log .user .thumb i {
    color: #fff !important; }

[data-primary-color="color_3"] .trade-app-content .card-body a {
  color: #002EFF; }

[data-primary-color="color_3"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #002EFF; }

[data-primary-color="color_3"] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #002EFF;
  color: white; }

[data-primary-color="color_3"] .social-icons a {
  background: #002EFF;
  color: white !important; }
  [data-primary-color="color_3"] .social-icons a i {
    color: white !important; }

[data-primary-color="color_3"] .settings_menu ul li a {
  color: #002EFF; }

[data-primary-color="color_3"] .toggle-checkbox:checked + .toggle-switch {
  background: #002EFF; }

[data-primary-color="color_3"] .file-upload-wrapper:before {
  background: #002EFF; }

[data-primary-color="color_3"] .sidebar-right .sidebar-right-trigger {
  background-color: #002EFF !important;
  color: #fff !important; }
  [data-primary-color="color_3"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important; }

[data-primary-color="color_3"] .form-control:focus,
[data-primary-color="color_3"] .form-control:active,
[data-primary-color="color_3"] .form-control.active {
  border-color: #002EFF; }

[data-primary-color="color_4"] .btn-primary {
  background-color: #FF2F00;
  border-color: #FF2F00; }
  [data-primary-color="color_4"] .btn-primary:hover, [data-primary-color="color_4"] .btn-primary:focus, [data-primary-color="color_4"] .btn-primary:active, [data-primary-color="color_4"] .btn-primary.active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none; }
  [data-primary-color="color_4"] .btn-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_4"] .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none; }

[data-primary-color="color_4"] .btn-outline-primary {
  background-color: transparent;
  color: #FF2F00;
  border-color: #FF2F00; }
  [data-primary-color="color_4"] .btn-outline-primary:hover, [data-primary-color="color_4"] .btn-outline-primary:focus, [data-primary-color="color_4"] .btn-outline-primary:active, [data-primary-color="color_4"] .btn-outline-primary.active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
    color: #fff; }
  [data-primary-color="color_4"] .btn-outline-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_4"] .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #cc2600;
    border-color: #cc2600;
    box-shadow: none;
    color: #fff; }

[data-primary-color="color_4"] .sidebar {
  background: #e62a00; }

[data-primary-color="color_4"] i.la,
[data-primary-color="color_4"] i.fa,
[data-primary-color="color_4"] .text-primary {
  color: #FF2F00 !important; }

[data-primary-color="color_4"] .bg-primary {
  background-color: #FF2F00 !important; }

[data-primary-color="color_4"] .owl-next,
[data-primary-color="color_4"] .owl-prev {
  background-color: #cc2600 !important; }
  [data-primary-color="color_4"] .owl-next i,
  [data-primary-color="color_4"] .owl-prev i {
    color: #fff !important; }

[data-primary-color="color_4"] .intro-form button {
  background: #FF2F00;
  color: #fff !important; }
  [data-primary-color="color_4"] .intro-form button i {
    color: #fff !important; }

[data-primary-color="color_4"] .intro-form-exchange .btn i {
  color: #fff !important; }

[data-primary-color="color_4"] .product-feature-box i {
  color: #fff !important; }

[data-primary-color="color_4"] .trust-content {
  border-color: #FF2F00; }

[data-primary-color="color_4"] .footer a {
  color: #FF2F00 !important; }

[data-primary-color="color_4"] .sort-view .search-timeline a:hover,
[data-primary-color="color_4"] .sort-view .search-timeline a:focus,
[data-primary-color="color_4"] .sort-view .search-timeline a:active,
[data-primary-color="color_4"] .sort-view .search-timeline a.active {
  color: #FF2F00; }

[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff; }

[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color="color_4"] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #FF2F00; }

[data-primary-color="color_4"] .badge {
  background: #FF2F00 !important;
  color: #fff !important; }

[data-primary-color="color_4"] .intro-video-play::after {
  background: #FF2F00; }

[data-primary-color="color_4"] .menu ul li a,
[data-primary-color="color_4"] .menu ul li a i,
[data-primary-color="color_4"] .menu ul li a.active i {
  color: #fff !important; }

[data-primary-color="color_4"] .page-title .page-title-content p {
  color: #FF2F00; }

[data-primary-color="color_4"] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color="color_4"] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color="color_4"] .profile_chart.card .card-header .duration-option a.active {
  background: #FF2F00; }

[data-primary-color="color_4"] .profile_log .user .thumb {
  background: #FF2F00;
  color: #fff !important; }
  [data-primary-color="color_4"] .profile_log .user .thumb i {
    color: #fff !important; }

[data-primary-color="color_4"] .trade-app-content .card-body a {
  color: #FF2F00; }

[data-primary-color="color_4"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #FF2F00; }

[data-primary-color="color_4"] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #FF2F00;
  color: white; }

[data-primary-color="color_4"] .social-icons a {
  background: #FF2F00;
  color: white !important; }
  [data-primary-color="color_4"] .social-icons a i {
    color: white !important; }

[data-primary-color="color_4"] .settings_menu ul li a {
  color: #FF2F00; }

[data-primary-color="color_4"] .toggle-checkbox:checked + .toggle-switch {
  background: #FF2F00; }

[data-primary-color="color_4"] .file-upload-wrapper:before {
  background: #FF2F00; }

[data-primary-color="color_4"] .sidebar-right .sidebar-right-trigger {
  background-color: #FF2F00 !important;
  color: #fff !important; }
  [data-primary-color="color_4"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important; }

[data-primary-color="color_4"] .form-control:focus,
[data-primary-color="color_4"] .form-control:active,
[data-primary-color="color_4"] .form-control.active {
  border-color: #FF2F00; }

[data-primary-color="color_5"] .btn-primary {
  background-color: #00CCD3;
  border-color: #00CCD3; }
  [data-primary-color="color_5"] .btn-primary:hover, [data-primary-color="color_5"] .btn-primary:focus, [data-primary-color="color_5"] .btn-primary:active, [data-primary-color="color_5"] .btn-primary.active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none; }
  [data-primary-color="color_5"] .btn-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_5"] .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none; }

[data-primary-color="color_5"] .btn-outline-primary {
  background-color: transparent;
  color: #00CCD3;
  border-color: #00CCD3; }
  [data-primary-color="color_5"] .btn-outline-primary:hover, [data-primary-color="color_5"] .btn-outline-primary:focus, [data-primary-color="color_5"] .btn-outline-primary:active, [data-primary-color="color_5"] .btn-outline-primary.active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
    color: #fff; }
  [data-primary-color="color_5"] .btn-outline-primary:not(:disabled):not(.disabled):active, [data-primary-color="color_5"] .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #009ba0;
    border-color: #009ba0;
    box-shadow: none;
    color: #fff; }

[data-primary-color="color_5"] .sidebar {
  background: #00b3ba; }

[data-primary-color="color_5"] i.la,
[data-primary-color="color_5"] i.fa,
[data-primary-color="color_5"] .text-primary {
  color: #00CCD3 !important; }

[data-primary-color="color_5"] .bg-primary {
  background-color: #00CCD3 !important; }

[data-primary-color="color_5"] .owl-next,
[data-primary-color="color_5"] .owl-prev {
  background-color: #009ba0 !important; }
  [data-primary-color="color_5"] .owl-next i,
  [data-primary-color="color_5"] .owl-prev i {
    color: #fff !important; }

[data-primary-color="color_5"] .intro-form button {
  background: #00CCD3;
  color: #fff !important; }
  [data-primary-color="color_5"] .intro-form button i {
    color: #fff !important; }

[data-primary-color="color_5"] .intro-form-exchange .btn i {
  color: #fff !important; }

[data-primary-color="color_5"] .product-feature-box i {
  color: #fff !important; }

[data-primary-color="color_5"] .trust-content {
  border-color: #00CCD3; }

[data-primary-color="color_5"] .footer a {
  color: #00CCD3 !important; }

[data-primary-color="color_5"] .sort-view .search-timeline a:hover,
[data-primary-color="color_5"] .sort-view .search-timeline a:focus,
[data-primary-color="color_5"] .sort-view .search-timeline a:active,
[data-primary-color="color_5"] .sort-view .search-timeline a.active {
  color: #00CCD3; }

[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link {
  color: #fff; }

[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link:hover,
[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link:focus,
[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link:active,
[data-primary-color="color_5"] .sort-view .coin-view .nav .nav-item .nav-link.active {
  background: #00CCD3; }

[data-primary-color="color_5"] .badge {
  background: #00CCD3 !important;
  color: #fff !important; }

[data-primary-color="color_5"] .intro-video-play::after {
  background: #00CCD3; }

[data-primary-color="color_5"] .menu ul li a,
[data-primary-color="color_5"] .menu ul li a i,
[data-primary-color="color_5"] .menu ul li a.active i {
  color: #fff !important; }

[data-primary-color="color_5"] .page-title .page-title-content p {
  color: #00CCD3; }

[data-primary-color="color_5"] .profile_chart.card .card-header .duration-option a:hover,
[data-primary-color="color_5"] .profile_chart.card .card-header .duration-option a:focus,
[data-primary-color="color_5"] .profile_chart.card .card-header .duration-option a.active {
  background: #00CCD3; }

[data-primary-color="color_5"] .profile_log .user .thumb {
  background: #00CCD3;
  color: #fff !important; }
  [data-primary-color="color_5"] .profile_log .user .thumb i {
    color: #fff !important; }

[data-primary-color="color_5"] .trade-app-content .card-body a {
  color: #00CCD3; }

[data-primary-color="color_5"] .buy-sell-widget .nav-tabs .nav-item .nav-link {
  color: #00CCD3; }

[data-primary-color="color_5"] .buy-sell-widget .nav-tabs .nav-item .nav-link.active {
  background: #00CCD3;
  color: white; }

[data-primary-color="color_5"] .social-icons a {
  background: #00CCD3;
  color: white !important; }
  [data-primary-color="color_5"] .social-icons a i {
    color: white !important; }

[data-primary-color="color_5"] .settings_menu ul li a {
  color: #00CCD3; }

[data-primary-color="color_5"] .toggle-checkbox:checked + .toggle-switch {
  background: #00CCD3; }

[data-primary-color="color_5"] .file-upload-wrapper:before {
  background: #00CCD3; }

[data-primary-color="color_5"] .sidebar-right .sidebar-right-trigger {
  background-color: #00CCD3 !important;
  color: #fff !important; }
  [data-primary-color="color_5"] .sidebar-right .sidebar-right-trigger i {
    color: #fff !important; }

[data-primary-color="color_5"] .form-control:focus,
[data-primary-color="color_5"] .form-control:active,
[data-primary-color="color_5"] .form-control.active {
  border-color: #00CCD3; }

[data-font="opensans"] {
  font-family: "Open Sans", sans-serif; }

[data-font="nunito"] {
  font-family: "Nunito", sans-serif; }

.demo_img {
  text-align: center;
  margin-bottom: 60px; }
  .demo_img .img-wrap {
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
    background: #131722;
    padding: 10px;
    border-radius: 5px;
    position: relative; }
    .demo_img .img-wrap::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #5d78ff;
      opacity: 0; }
    .demo_img .img-wrap.light {
      background: #fff; }
    .demo_img .img-wrap:hover::after, .demo_img .img-wrap:focus::after {
      opacity: 0.5; }
  .demo_img img {
    border-radius: 5px; }

.intro-demo_img {
  box-shadow: 0px 36px 48px rgba(93, 120, 255, 0.25);
  position: relative;
  overflow: hidden; }
  .intro-demo_img img {
    border-radius: 5px; }
  .intro-demo_img .dark-img {
    position: absolute;
    width: 90%;
    right: 0px;
    bottom: 0px; }



/* Pages Signup */




.custom-flag-select {
  display: block;
  width: 100%;
 
  font-size: 0.875rem;
  font-weight: 1600;
  line-height: 1.5;
  color: #a1a0a7;
  background-color: #000;
  background-clip: padding-box;

  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }





  



/* Pages PageTitle */

.div-1 {
  background-color: red;
}


  div.container-not-approved-status-fluid{

    padding-left:0px;
    padding-right:0px;
    background-color:red;
}

div.container-approved-status-fluid{

  padding-left:0px;
  padding-right:0px;
  background-color:green;
}


